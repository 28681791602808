@import '../../app/styles/variables.css';

.signout {
    display: flex;
}

.signout a {
    width: 100%;

    display: flex;
    padding: 0 1rem;
    line-height: var(--aside-nav-height);
    position: relative;

    color: inherit;
    opacity: .75;
}
.signout a:hover,
.signout a:focus {
    background-color: var(--min-black);
    opacity: 1;
}

.icon {
    line-height: inherit;
    margin-right: 1rem;
    top: 0;
    min-width: 1.5rem;
    text-align: center;
}

.text {
    font-weight: 500;

    text-transform: capitalize;
}
