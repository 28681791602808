@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.wrap {
  display: flex;
  flex-direction: column;
  margin: 20px;

  & .wrap_content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2.5fr 1fr;
    gap: 5px;
    justify-items: stretch;
    align-items: center;
    margin-bottom: 20px;

    & .buttons {
      display: flex;
      gap: 4px;
    }
  }

  & .header {
    align-self: center;
    font-weight: var(--font-weight-medium);
  }

  & .item {
    display:flex;
    justify-content:space-between;
    width:100%;
    gap:10px;
    margin-top:5px;
    margin-bottom:5px;
    text-align:left;
  }
}

.link:hover {
  text-decoration: underline;
}

.buttons {
  display: flex;
  justify-content: space-between;

  & .button_add_complaint {
    width: 100px;
  }
}

.input_date{
  padding: 0;
  padding-bottom: 6px;
}

.field_name {
  overflow-wrap: anywhere;
}

.comment{
  width:100%;
  resize: none;
  border: 1px solid rgba(160, 160, 160, 0.2);
}

.comment:focus {
  border: 2px solid var(--primary);
}

.loading {
  margin: 20px 0;
}

