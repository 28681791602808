@import '../../../../../app/styles/variables.css';

.loading {
  display: flex;
  height: 336px;
}

.top,
.bottom {
  display: flex;
  padding: 1rem;
  margin-bottom: 20rem;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
}

.row {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  &.byContent {
     display: inline-block;
  }

  & .label {
    margin-right: 20px;
  }

  & .edit-button {
    margin-left: 8px;
    width: auto;
  }

  & .inputIsd {
    width: 100px;
  }

  & .action {
    margin-left: 8px;
  }
}
