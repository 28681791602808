@import '../../../app/styles/variables.css';

.dialog {
  padding: 24px;
}
.dialog .header {
  font-size: var(--font-size-md);
  margin-bottom: 2rem;
}

.radios {
  display: flex;
  flex-direction: column;
}

.radio {
  margin-right: 20px;
  padding-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  padding-top: 24px;
  margin-left: auto;
}

.action {
  margin-left: 12px;
}
