@import '../../../../app/styles/variables.css';

.wrapper {
  display: flex;
  flex-direction: column;

& h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
  }
}

.dates {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
}

.description {
  margin-top: 20px;
  color: var(--gray-text);
  line-height: 1.3;

& p + p {
    margin-top: 3px;
  }
}

.from-trip-wrapper {
  display: flex;
  flex-direction: row;

& .title-wrapper {
    display: flex;
    flex-direction: column;
  }

& .dates-wrapper {
    display: flex;
    flex-direction: row;
    color: var(--gray-text);
    margin-top: 4px;
    font-size: 13px;
  }

& h3 {
    margin: 0;
    font-size: 16px;
    text-transform: none;
    font-weight: 500;
  }

& .description-wrapper {
    color: var(--gray-text);
  }
}

.icon {
  min-width: 55px;
}
