@import '../../../../../app/styles/variables.css';

.item {
  display: flex;
  flex-direction: column;

  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid var(--border-color);
}

.item:first-child {
  margin-top: 0;
}

.item:last-child {
  border: none;
  padding-bottom: 0;
}

.row {
  display: flex;
  align-items: flex-end;
}

.col {
  display: flex;
  flex-direction: column;
}

.col h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.col_departure,
.col_arrival {
  flex: 1;
}

.col_airline {
  align-items: center;
}

.col_arrival {
  align-items: flex-end;
}

.col h3.time {
  margin: 12px 0 4px 0;
}

.duration {
  margin: 4px 0;
  font-weight: 500;
}

.details,
.fare {
  font-size: 13px;
  color: var(--dark-gray-text);
}

.rate {
  font-size: 13px;
}

.rate b {
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
}

.segments-toggle {
  cursor: pointer;
}

.exchange-count-text {
  border-bottom: 1px dotted var(--border-color-lt);
}

.exchange-count-icon {
  vertical-align: middle;
}

.segments {
  margin: 15px 0 0;
  padding: 20px 20px;
  background-color: var(--main-background-color);
  cursor: pointer;
}

.voucher-details {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.voucher-details span {
  font-size: 13px;
  color: var(--dark-gray-text);
  margin-right: 3px;
}

.rate-and-fare {
  display: flex;
  flex-direction: row;
}

.pnr {
  font-weight: bold;
  font-size: 14px;
}
