@import '../../../../../app/styles/variables.css';

.dialog {
  padding: 24px;
  min-height: 120px;
}

.header {
  text-align: center;
  font-size: 16px;
  font-weight: 500;

  margin-bottom: 24px;
}

.row {
  margin-bottom: 24px;
}

.date-error :global(.sw-datepicker) {
  border: 1px solid var(--brand);
  background-color: var(--light-pink);
}

.error-text {
  color: var(--brand);
  font-size: 11px;
  margin: 4px 0;
}

.label {
  opacity: 0.6;

  padding-bottom: 6px;
}

.btn_delete {
  margin-right: 70px
}

.actions {
  display: flex;
  justify-content: flex-end;

  padding-top: 28px;
}

.action {
  margin-left: 12px;
}

.wrapper {
  padding-bottom: 20px;
}

.error {
  position: absolute;
  width: 360px;
  color: var(--brand);
  font-size: 12px;
  margin: 4px 0;
}
