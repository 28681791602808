@import '../../../../../app/styles/variables.css';

.item {
  display: flex;
  flex-direction: column;

  flex: 1;
}

.row {
  display: flex;
}

.row h3,
.col h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.row h3 {
  display: flex;
  flex: 1;
}

.end_h3 {
  justify-content: flex-end;
}

.col {
  display: flex;
  flex-direction: column;

  margin: 12px 0 20px 0;
}

.date_title,
.details {
  font-size: 13px;
  color: var(--dark-gray-text);
}

.date_title {
  margin-bottom: 4px;
}

