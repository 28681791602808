.divider {
  padding-bottom: 10px;
}

.hotel_wrap {
  display: flex;
  gap: 10px;
  width: 100%
}

.hotel_item {
  width: calc(100% / 5);
}

.hotel_conditions {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.autocomplete {
  display: flex;
  position: absolute;
  background-color: var(--white);
  z-index: 6;
  max-height: 300px;
  width: 500px;
  top: auto;
  left: auto;
  overflow-y: auto;
  box-sizing: border-box;
}

.items_wrap {
  width: 100%;
}

.autocomplete_item {
  display: flex;
  justify-content: space-between;
  padding: .3rem 1rem;
  width: 100%;
  align-items: center;
  line-height: var(--font-size-md);
  min-height: var(--aside-nav-height);

  & .autocomplete_item_text {
    width: 66%;
  }

  & .autocomplete_item_label {
    font-size: 12px;
    color: var(--gray-text);
  }

  &:hover {
    opacity: 1;
    background-color: var(--min-black);
    cursor: pointer;
  }
}

.input {
  padding: 0;
}

.condition {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% / 5);
}

.checkbox_width {
  width: auto;
}

.hotel_item_selector {
  height: 100%;
}

.contacts {
  display: flex;
  gap: 10px;
  width: 100%;
}

.width {
  width: calc(100% / 5);
  max-width: 312px;
}