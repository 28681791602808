@import '../../../../../app/styles/variables.css';

.wrap {
  padding: 16px;
  background-color: var(--full-white);
}

.wrapper-comment {
  max-width: 90%;
}

.wrapper_nalog {
  display: flex;
  align-items: center;

  & .button {
    margin-left: 20px;
    max-width: 120px;
  }
}

.comment {
  word-wrap: break-word;
  margin-left: 4px;
}

.loading {
  display: flex;
  height: 200px;
}

.header {
  font-weight: bold;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-range {
  display: flex;
  align-items: baseline;
}

.date-text {
  margin: 16px;
}

.date-text:first-child {
  margin-left: 0;
}

.position {
  display: flex;

  margin-left: 12px;
}

.link {
  cursor: pointer;
  border-bottom: 1px dashed var(--dark-black);
}

.link:hover {
  color: var(--brand);
  border-bottom: 1px dashed var(--brand);
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin: 24px 0;
}

.actions :global(.sw-button) {
  width: auto;
}

.action {
  margin: 6px 0;
}

.wrap .row,
.row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
}
.wrap .row:last-child,
.row:last-child {
  margin-bottom: 0;
}

.row.button-change {
  align-items: center;
  margin: 0;
}

.row.button-change button {
  width: auto;
  margin-left: 20px;
}

.row.button-change-edo button {
  width: auto;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form label {
  font-size: var(--font-size-sm);
  opacity: .6;
}

.form-dialog {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}

.button-correction {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray-sw-ui);

  & :hover {
    color: var(--full-black);
  }
}

.wrapper_checkbox {
  margin-top: 20px;
}
