@import '../../../app/styles/variables.css';

@keyframes ripple-animation {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(100);
    opacity: 0;
  }
}

.button {
  color: var(--dark-black);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 36px;
  letter-spacing: 0.03em;

  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 1rem;

  border: none;
  border-radius: 2px;
  cursor: pointer;
  touch-action: manipulation;

  background-image: none;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  position: relative;
  overflow: hidden;

  opacity: .8;

  background-color: transparent;
}

.button:hover {
  opacity: 1;
}

.button.flat:hover {
  background-color: var(--min-black);
}

.button i {
  margin-right: 5px;
}

.with_wrap {
  border: 1px solid var(--min-black);
  margin: 10px 0;
}

.error_message {
  margin: 10px 0;
  color: var(--danger);
}

.button:disabled {
  cursor: not-allowed;
}
