@import '../../app/styles/variables.css';

.main {
  padding: 20px;
}

.main-title {
  font-size: var(--font-size-lg);
  margin: 30px 0 20px 0;
  padding-left: 20px;
}

.wrap {
  padding: 20px 0;
}

.row {
  display: flex;
  justify-content: left;
}

.row-title {
  margin-bottom: 20px;
}

.col {
  margin: 5px 0;
  text-align: left;
}

/* Lost generated */
.col-1-7 {
  width: calc(99.9% * 2/7 - (30px - 30px * 2/7));
}

.col-1-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-1-7:last-child {
  margin-right: 0;
}

.col-1-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-1-7:nth-child(7n + 1) {
  clear: both;
}

.col-2-7 {
  width: calc(99.9% * 3/7 - (30px - 30px * 3/7));
}

.col-2-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-2-7:last-child {
  margin-right: 0;
}

.col-2-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-2-7:nth-child(7n + 1) {
  clear: both;
}
/* Lost generated end */

.title {
  font-weight: var(--font-weight-bold);
}

.test-title-wrap {
  padding: 20px;
  margin-left: 20px;
}

.test-list-wrap {
  padding: 20px;
  background-color: var(--full-white);
  margin: 0 0 20px 20px;
}
