.vat {
  gap: 10px;
  display: flex;

  align-items: baseline;
}

.add_button {
  width: 200px;
}
