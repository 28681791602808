@import '../../../../../app/styles/variables.css';

.item {
  display: flex;
  flex-direction: column;
}

.item-train {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.col h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.col h3.time {
  margin: 12px 0 4px 0;
}

.col_departure,
.col_arrival {
  flex: 1
}

.col_departure--h3 {
  text-align: start;
}

.col_departure--info,
.col_arrival--info {
  justify-content: flex-end;
  height: 100%;
}

.col_arrival,
.col_arrival--info {
  align-items: flex-end;
}

.col_arrival--h3 {
  text-align: end;
}

.col_train {
  justify-content: flex-end;
  color: var(--dark-gray-text);;
}

.details {
  margin: 4px 0;
}

.main-text {
  color: var(--full-black);
}

.date {
  font-size: 13px;
  color: var(--dark-gray-text);;
}

.capitalize {
  text-transform: capitalize;
}

.type {
  margin-top: 20px;
}

.type b {
  font-size: 13px;
  font-weight: 500;
}

