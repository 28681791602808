@import '../../../../app/styles/variables.css';
@import '../../../../app/styles/mixin.css';


.row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;

  padding: 10px 16px;
  margin-bottom: 0;
  &:hover {
    background-color: var(--min-black);
  }

  /* Lost generated */
  & .col-1-7 {
    width: calc(99.9% * 1/7 - (30px - 30px * 1/7));
  }
  & .col-1-7:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  & .col-1-7:last-child {
    margin-right: 0;
  }
  & .col-1-7:nth-child(7n) {
    margin-right: 0;
    float: right;
  }
  & .col-1-7:nth-child(7n + 1) {
    clear: both;
  }
  & .col-1-8 {
    width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  }
  & .col-1-8:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  & .col-1-8:last-child {
    margin-right: 0;
  }
  & .col-1-8:nth-child(8n) {
    margin-right: 0;
    float: right;
  }
  & .col-1-8:nth-child(8n + 1) {
    clear: both;
  }
  /* Lost generated end */

  & .data-services .icon-services {
    margin: auto 0;
    font-size: 18px;
  }
  & .data-services {
    flex-direction: row;
    display: flex;
  }
}

.row:last-child {
  margin-bottom: 0;
}

.expense-report {
  margin-top: 10px;
}

.expense-remove-dialog {
  padding: 20px;
}

.expense-remove-dialog-buttons {
  margin-top: 10px;
}

.expense-report-delete {
  color: var(--danger);
}

.mice {
  width: 18px;
  height: 18px;
}

.bus {
  padding: 0px 1px 2px 1px;
  margin: auto 0;
  width: 18px;
  height: 18px;
}




