.user_selector {
  padding: 0;
  width: 250px;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-column-gap: 50px;
  margin-bottom: 20px;
}

