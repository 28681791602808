.wrapper{
    display: flex;
    margin: 25px;
    flex-direction: column;
    width: 750px;
}

.wrapper_cap {
    display: flex;
    gap: 15px;
}

.wrapper_content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 0.5px solid;
}

.wrapper_column {
    display:flex;
    flex-direction: column;
    width: 50%;
    border: 0.5px solid;
}

.wrapper_select {
    width: 50%;
    border: 0.5px solid;
    padding-top: 5px;
}

.wrapper_text {
    padding-left: 15px;
    border-top: 1px solid;
}

.select_header {
    width: 40%;
}

.wrapper_header {
    border: 0.5px solid;
    width: 49%;
    padding-left: 15px;
}

.dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    min-height: 120px;
}

.row {
    margin: 0;
    align-items: center;
    min-height: var(--aside-nav-height);
}

.right {
    margin-top: 24px;
    margin-left: auto;
    justify-content: flex-end;
}

.button_save {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.wrapper_line {
    display: flex;
}
