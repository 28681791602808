@import '../../../app/styles/variables.css';


.wrap {
  height: 100%;
  position: relative;
  width: var(--aside-width);
  background-color: var(--white);
}

.menu {
  width: var(--aside-width);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  transition: width 0.5s ease-out;
  height: 100%;
  position: fixed;
}

.menu_close {
  width: 53px;
}

.button_wrap {
  position: absolute;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--full-white);
  border-radius: 8px;
  width: 20px;
  top: 41px;
}


.header {
  border: none;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .logo {
   height: 26px;
  }
}

.profile {
  padding: 0.75rem 1rem;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex: 1;

  margin: 1em 0 1em 0;

  overflow-y: auto;
}

.footer {
  border-top: 1px solid var(--border-color);
  overflow-y: auto;
}
