@import '../../../app/styles/variables.css';

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem;
  min-height: 120px;
}

.loading {
  display: flex;
  height: 254px;
}

.row {
  margin: 0;
  align-items: center;
  min-height: var(--aside-nav-height);
}

.text {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  padding-top: 12px;
  margin-left: auto;
}

.action {
  margin-left: 12px;
}

.wrap {
  font-weight: 500;
  font-size: 0.95rem;
}

.info {
  font-size: 0.9rem;
  padding-top: 5px;
}

.notification {
  color: var(--danger);
}

.confirm {
  padding-top: 15px;
}
