@import '../../../app/styles/variables.css';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;

  padding: var(--main-padding);
}
