@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.wrapper_block {
  display: flex;
  flex-direction: column;
  width: inherit;
  outline: none;
  width: 190px;
}

.wrap {
  display: flex;
  flex-direction: column;

  border-radius: 2px;
  border: 1px solid var(--gray-sw-ui);
  width: inherit;

  position: relative;

  cursor: pointer;
  outline: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;

  & .icon {
    display: flex;
    justify-content: center;

    width: 14px;
    height: inherit;

    font-size: 22px;
    opacity: .7;

    transition: transform .4s;
  }

  & .icon.open {
    transform: rotate(-180deg);
  }
}

.label {
  flex: 1;
  margin-right: 10px;
}

.placeholder {
  flex: 1;
  margin-right: 10px;

  color: var(--light-black);
  font-weight: var(--font-size-md);
  font-size: var(--font-size-sm);
}

.list {
  display: flex;
  flex-direction: column;
  width: inherit;

  position: absolute;
  top: 100%;
  left: -1px;
  z-index: 2000;

  margin-top: 1px;
  border: 1px solid var(--gray-sw-ui);
  background-color: var(--full-white);
  border-top: none;
  border-radius: 2px;
}

.list_scroll {
  height: 194px;
  overflow-x: hidden;
}

.item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid var(--border-color-sw-ui);
  opacity: .7;
}

.item:hover {
  background-color: var(--min-black);
  opacity: 1;
}

.item:last-child {
  border-bottom: none;
}

.optional {
  width: inherit;
  padding: 4px 25px 4px 10px;
}

.input {
  position: relative;
  width: inherit;
  border: none;
  font-size: 14px;
}

.optional .icon_check {
  font-size: 19px;
  font-weight: var(--font-weight-bold);
  color: var(--light-black);

  position: absolute;
  right: 3px;

  cursor: pointer;
}

.text_check {
  right: 4px !important;
}

.optional .icon_check:hover {
  color: var(--success);
}

.error-msg {
  line-height: 2;
  color: var(--danger);
}
