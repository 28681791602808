@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.list_wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .requaired_field {
    border-radius: 8px;
    border: 2px dashed var(--border-color-dached);
    padding: 16px 25px;
    text-align: center;
    cursor: pointer;

    & .title {
      color: var(--text-muted);
      font-size: 16px;
      line-height: 100%;
    }
  }
}

.drop_wrap {
  display: flex;
  width: 436px;
  min-height: 617px;
  border: 3px solid var(--border-color-dark);
  border-radius: 8px;
  position: relative;

  & .common {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    & .header {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.highlighted {
  border-style: dashed;
}

.droppable {
  border-style: dashed;

  & .droppable_panel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 18px;
    background-color: var(--dark-white);
    border-radius: 8px;
  }
}