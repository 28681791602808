.time {
    width: 60px;
    border: none;
}

.border {
    width: 100%;
    padding: 9px;
    border: 1px solid #e5e5e5;
    font-weight: normal;
    font-size: 14px;
}
