@import '../../../../app/styles/variables.css';

.dialog {
  padding: 20px;
  min-width: 400px;

  & .titles {
    display: flex;
    gap: 12px;
  }

  & .list_ids {
    column-count: 3;
    margin: 12px 0;

    & .inactive_id {
      color: var(--danger);
      word-wrap: break-word;
    }
  
    & .active_id {
      color: var(--success);
      word-wrap: break-word;
    }
  }

  & .selected_provider {
    border: 1px solid var(--border-color);
  }
}
