@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.main_wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: var(--main-padding);
  align-items: flex-end;
}

.wrap {
  padding: var(--main-padding);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tab_link {
  width: 100%;
}

.content_wrap {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: var(--full-white);
  box-shadow: 0 1px 3px var(--box-shadow-dark);
}

.types_services_wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .types_services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & .title {
      color: var(--full-black);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%;
    }

    & .block {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      & .icon {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  & .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    & .block {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 16px;
      flex-wrap: wrap;
      border-radius: 8px;
      background: var(--secondary-background-color);

      & .item {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}

.collapsible_wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.header_collapsible_wrap {
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: var(--full-black);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.collapsible_title_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    & .title {
      color: var(--full-black);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;

    & .head {
      display: flex;
      width: 100%;
      align-items: flex-start;
      border-radius: 8px;
      background: var(--main-background-color);
      color: var(--full-black);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
    }

    & .col_1 {
      display: flex;
      width: 18%;
      padding: 8px 10px;
      align-items: center;
      gap: 4px;
    }

    & .col_2 {
      display: flex;
      flex-direction: column;
      width: 60%;
      padding: 8px 10px;
      align-items: flex-start;
      gap: 8px;

      & .cell {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    & .col_3 {
      display: flex;
      flex-wrap: wrap;
      width: 22%;
      padding: 8px 10px;
      align-items: center;
      gap: 20px;
    }

    & .row {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 1px solid var(--body-bg);
      background: var(--white);
    }

    & .text {
      color: var(--full-black);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
}

.document_setup_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  & .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    & .title {
      color: var(--full-black);
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%;
    }

    & .subtitle {
      color: var(--full-black);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%;
    }

    & .multiselect_wrap {
      display: flex;
      width: 100%;
      align-items: flex-start;
      align-content: flex-start;
      gap: 24px;
      flex-wrap: wrap;

      & .multiselect {
        width: 31%;
        height: 40px;
      }
    }
  }
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.modal_content {
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal_buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  & .modal_button {
    width: 20%;
    justify-content: center;
  }
}

.block_wrap {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--secondary-background-color);

  & .block_title {
    color: var(--full-black);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  & .block_content {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    & .list_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      & .hint {
        color: var(--text-muted);
        line-height: 120%;
      }
    }
  }
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--filter-boder);
  box-sizing: border-box;
  border-radius: 8px;
}

.buttons_wrap {
  display: flex;
  gap: 24px;
}