@import '../../../../../app/styles/variables.css';

.wrap_layout {
  display: flex;
  flex-direction: row;
}

.icon {
  display: flex;

  width: 55px;
  font-size: 22px;
  color: var(--dark-gray-text);
}

.children {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media all and (-ms-high-contrast:none) {
  .children {
    display: block;
  } /* IE11 */
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 0 0 20%;
}

.price {
  display: flex;
  font-size: 22px;
}
