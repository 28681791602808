@import '../../../../app/styles/variables.css';

.wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .buttons {
    display: flex;
  }

  & .button {
    min-width: 200px;
  }

  & .dialog {
    width: 800px;
    padding: 20px;
  }

  & .inactive_id {
    color: var(--danger);
    word-wrap: break-word;
  }

  & .active_id {
    color: var(--success);
    word-wrap: break-word;
  }

  & .error {
    color: var(--danger);
  }

  & .block_giata_id {
    display: flex;
    flex-direction: column;

    & .giata_ids_select {
      display: flex;
      gap: 12px;
    }

    & .select_giata {
      width: 200px;
      border: 1px solid gray;
      border-radius: 8px;
      padding: 12px;
  
      & .provider_info {
        margin-top: 5px;
      }
    }
  }
}