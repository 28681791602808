.wrapper {
  margin-bottom: 20px;

  & .header {
    display: flex;
  }

  & .content {
    margin-bottom: 50px;

    & .item {
      display: flex;
      align-items: baseline;

      & .item_title {
        margin: 20px 0;
        font-size: 18px;
      }

      & .item_content {
        display: flex;
        margin-left: 20px;
      }

      & .email {
        width: 50%;
      }
    }

    & .vat {
      & .vat_title {
        margin: 20px 0;
        font-size: 18px;
      }

      & .vat_content {
        display: flex;
        width: 25%;
      }
    }
  }

  & .delete_button {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
}
