@import '../../../../../../app/styles/variables.css';

.hotel_wrap {
  display: flex;
  gap: 10px;
  width: 100%
}

.hotel_item {
  width: calc(100% / 5);
}

.guest_info {
  gap: 30px;
}

.width {
  width: calc(100% / 5);
  max-width: 312px;
}

.hotel_room_block {
  display: flex;
  padding-bottom: 15px;
}

.hotel_room_info {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid rgba(160, 160, 160, 0.2);
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hotel_number_wrap {
  position: absolute;
  right: 25px;
  top: 15px;
}

.hotel_room_tools {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  padding-left: 50px;
}

.selectors {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.conditions_input {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
}

.type_of_room {
  height: 100%;
  font-size: var(--font-size-sm);
}

.commission_input {
  padding: 0;
  width: 100%;
}

.date_pickers {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.date_picker_wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% / 5);
}

.date_picker {
  padding: 0;
}

.divider {
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--gray-text);
}

.hotel_conditions {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.condition {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% / 5);
}

.cancellation_fees {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.free_cancellation {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100%/5);
}

.cancellation_for_client {
  gap: 10px;
}

.date_picker_wrap_free_cancellation {
  display: flex;
  gap: 5px;
  width: calc(100% / 5);
}

.button_wrap {
  font-size: 11px;      

  &:disabled {
    background-color: #e5e5e5;
    color: var(--black);
  }
}

.input {
  padding: 0;
}

.room_info {
  align-items: end;
}