@import '../../../../../app/styles/variables.css';

.dialog {
  padding: 24px;
}

.width_dialog {
  min-height: 150px;
}

.dialog h3 {
  margin-top: 0;
}

.dialog h4 {
  margin: 24px 0 12px 0;
}

.loading {
  display: flex;
  height: 254px;
}

.loading_margin {
  margin-top: 30px;
}

.radios {
  display: flex;
}

.radio {
  margin-right: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  padding-top: 24px;
  margin-left: auto;
}

.action {
  margin-left: 12px;
}

.select {
  padding-bottom: 15px;
}

.dialog .title-center {
  margin-bottom: 2rem;
}

.title-center {
  font-size: 1.2rem;
  justify-content: center;
  font-weight: 500;
}

.dialog .wrap {
  width: 100%;
  padding-bottom: 10px;
}

.dialog label {
  font-size: var(--font-size-sm);
  opacity: .6;
}

.wrap .button-full-width {
  width: 100%;
}

.datepicker-no-padding {
  padding: 0;
}

.person_wrap {
  border-radius: 2px;
  border: 1px solid #ccc;
  outline: none;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 10px;
}

.person_text {
  overflow: hidden;
  overflow-wrap: break-word;
}

.auto-input {
  position: relative;
}

.auto-input > div {
  width: 100%;
}

.auto-input div input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  min-height: 34px;
  padding: 2px;
  color: inherit;
  box-sizing: border-box;
  font: inherit;
}

.auto-input div input:focus {
  border-color: var(--primary);
  border-bottom-width: 2px;
  padding-bottom: 1px;
}

.hotels {
  margin: 5px 0 30px;

  & .hotel {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .hotel_name {
      width: 90%;
    }
  }
}

.hotels h4 {
  margin: 5px 0 10px 0;
}

.autocomplete_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--full-white);
  opacity: 1;
  z-index: 100;
  padding: 10px;
  width: 100%;
  line-height: var(--font-size-md);
  min-height: var(--aside-nav-height);

  cursor: pointer;

  & .autocomplete_item_text {
    width: 66%;
  }

  & .autocomplete_item_label {
    font-size: 12px;
    color: var(--gray-text);
  }
}

.input_row {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.info {
  color: red;
}

.datepicker {
  position: relative;
  width: 100%;
}

.button_add_penalty {
  margin: 10px 0;
}

.button_calculate {
  width: fit-content;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: var(--light);
}

.title_wrapper {
  text-align: center;
  margin-top: -10px;
}

.eventsTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 10px;

  & th {
    padding: 8px;
    border: 1px solid var(--border-color);
    background-color: var(--light);
    text-align: center;
    cursor: pointer;

    & .headerContent {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  & td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
    border: 1px solid var(--border-color);
    text-align: center;
  }

  & tbody {
    & tr:hover {
      background-color: var(--gray-sw-ui);
    }
  }
}

.table {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1.6fr 2.5fr 1.5fr 1.3fr;
  align-items: center;

  min-height: 40px;
  margin-top: 5px;

  & .name {
    width: 90%;
    text-align: justify;
  }

  & .status {
    text-align: center;
  }
}

.table_default {
  display: grid;
  grid-template-columns: 3.5fr 1.5fr 1.6fr 2.5fr 1.5fr 1.3fr;

  margin-top: 20px;
}

.point {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icons {
  margin-left: 5px;
}

.button {
  background-color: var(--light) !important;
}

.button:hover {
  background-color: var(--gray-text) !important;
}

.empty {
  text-align: center;
}
