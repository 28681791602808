.modal {
  width: 600px;
  min-height: 100px;

  display: flex;
  flex-direction: column;
}

.title {
  font-weight: var(--font-weight-bold);
}

.buttons_section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.operation_detail {
  display: flex;
  flex-direction: column;

  gap: 20px;
  margin-top: 20px;
}

.operation_detail_wrapper {
  display: flex;
  gap: 20px;
}

.price {
  display: flex;
  flex-direction: column;
}

.price_row {
  gap: 20px;
  display: flex;
}

.vat_wrapper {
  display: flex;
  flex-direction: column;
}

.vat {
  gap: 10px;
  display: flex;

  align-items: baseline;
}

.error {
  color: var(--danger)
}

.warn {
  color: var(--warn)
}

.col-1-3:last-child {
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  width: calc(100% / 3);
}
