@import '../../../../app/styles/variables.css';

.wrap {
  margin-bottom: 16px;
  padding: 16px;
  min-height: 350px;
  background-color: var(--full-white);
}

.inner_wrap {
  padding: 16px;
}

.form_wrap {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;

  & .label {
    padding: .5rem 1rem;
    color: var(--dark-gray-text);
  }


  & .pre_send_warning {
    font-size: 14px;
    color: var(--warn);
  }
}

.form_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 85%;
}

.selectors {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.selector {
  width: calc(100% / 4);
  padding: 3px;
}

.date_picker {
  padding: 0;
}

.condition {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% / 5);
}

.input {
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user_selector {
  padding: 0;
  width: 250px;
}
