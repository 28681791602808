@import '../../../app/styles/variables.css';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 350px;
  min-height: 100px;
  padding: 25px 10px;
  border: 2px dashed rgba(0, 0, 0, 0.30);
  border-radius: 10px;
  background-color: #fff;
}

.input {
  display: none;
}

.upload_icon {
  font-size: 55px !important;
  color: rgba(0, 0, 0, 0.3);
  transition: ease-in-out color 300ms;
  user-select: none;

  &_wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.highlight {
  & .upload_icon {
    color: var(--brand);
  }
}

.close {
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.5;
}

.file {
  display: flex;
  flex-direction: row;
  align-items: center;

  &_icon {
    font-size: 45px !important;
    color: rgba(0, 0, 0, 0.3);
    user-select: none;
  }
}

.validation {
  margin-top: 20px;
}

.loader {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}

.description {
  white-space: pre-wrap;
  line-height: 1.5;
  letter-spacing: 0.8px;

  &_wrap {
    text-align: center;
    font-weight: 400;
    opacity: 0.6;
  }
}

  
