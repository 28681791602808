@import '../../../../../app/styles/variables.css';

.provider {
  margin-top: 15px;
  font-size: 13px;
}

.provider-title {
  font-weight: var(--font-weight-medium);
}

.provider-name {
  color: var(--dark-gray-text);
}
