@import '../../app/styles/variables.css';

.main {
  padding: 1.5rem;
}

.row {
  display: flex;
  justify-content: left;
  padding-left: 10px;
  margin-bottom: 10px;
}

.row-title {
  margin-bottom: 20px;
  color: var(--dark-gray-text);
}

.col {
  margin: 5px 5px;
  text-align: left;
}

.wrap {
  padding: 20px;
  background-color: var(--full-white);
}

.main-title {
  font-size: var(--font-size-lg);
  margin: 30px 0 20px 0;
  padding-left: 20px;
}

.render-panel {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;
  min-height: 40px;
  background-color: var(--white);
}

.top-control-panel {
  display: flex;
  justify-content: flex-end;
}

.bottom-control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-button {
  width: 160px;
}

.button-to-top {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  left: 0;
}

.button-to-top:hover {
  background-color: var(--min-black);
  opacity: 1;
}

.button-to-top span {
  padding-left: 5px;
}

.button-wrap {
  margin-right: 50px;
  margin-bottom: 10px;
}

.company-name {
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.company-item:hover {
  background-color: var(--main-background-color);
  transition: .3s cubic-bezier(0, 0, 0.2, 1);
}

.select-company {
  width: auto;
  align-self: end;
  margin: 3px 15px;
}

.title:hover {
  color: var(--brand);
}

.select-title {
  color: var(--brand);
}

.file-type-box {
  display: none;
  align-items: center;
  margin-left: auto;
}

.visible {
  display: flex;
}

.dialog {
  padding: 20px;
}

.dialog-text {
  margin-bottom: 40px;
}

.dialog-button {
  display: flex;
  justify-content: flex-end;
}

.send {
  margin-right: 20px;
}

.filter {
  margin-bottom: 20px;
  padding: 0 40px;
  width: 30%;
}

.count {
  margin-top: 10px;
  color: var(--brand);
}

.main_filter{
  width: 20%;
  margin-left: 20px;
}

.tooltip {
  width: 310px;
  padding: 10px 15px;
  left: 12%;
}

.actions {
  display: flex;
  align-items:center;
  margin-left: 20px;
}
