@import '../../app/styles/variables.css';

.wrap {
    display: flex;
    align-self: stretch;

    width: 100%;
}

.wrap [type=search] {
    outline: none;
    border: none;
    border-radius: 0;
    background: var(--full-white);

    width: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}