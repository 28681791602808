.wrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  & .row {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  & .wrapper {
    padding-top: 10px;
  }

  & .row-header {
    padding: 25px 0;
  }

  & .providerHeader {
    padding-top: 15px;
    font-size: 16px;
  }

  & .feeRow {
    padding: 13px 0;
    justify-content: space-between;
  }

  & .border {
    border-top: 1px solid gray;
  }

  & .col-1-3 {
    width: calc(100% / 3);
  }

  & .col-1-4 {
    width: calc(100% / 4);
  }

  & .col-1-5 {
    width: calc(100% / 5);
  }

  & .col-1-6 {
    width: calc(100% / 6);
  }

  & .col-1-7 {
    width: calc(100% / 7);
  }

  & .col-1-9 {
    width: calc(100% / 9);
  }

  & .header {
    font-size: 1rem;
    padding: 20px 0;
  }

  & .buttons {
    padding-top: 10px;
    padding-left: 15px;

    & .button_delete {
      background-color: #eee;
      color: black;
    }
  }
}


