.wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .img_input_wrpap {
    width: 77%;
    box-sizing: border-box;
  }
  & .img_button_wrpap {
    box-sizing: border-box;
    align-self: center;
  }
}