@import '../../../../../app/styles/variables.css';

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loading-wrap {
  display: flex;
  flex: .1;
}

.item {
  padding-bottom: 5px;
  display: flex;
  align-items: baseline;

  font-size: 14px;
}

.item:last-child {
  padding: 0;
}

.number {
  margin: 0 10px 0 0;
  color: var(--dark-gray-text);
}

.link {
  display: flex;
  align-items: center;

  font-weight: 400;
}

.link:hover {
  cursor: pointer;
}

.employee-icon {
  font-family: 'smartway-icons', serif;
  font-size: 19px;

  position: relative;

  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  margin: 0 10px 0 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.employee-optional {
  color: var(--dark-white);
  font-style: italic;
}

.employee-icon:before {
  content: "\E952";
}

.employee-icon:after {
  content: "\E928";

  position: absolute;
  bottom: -5px;
  right: -4px;
  font-size: 55%;
  color: var(--brand);
}

.suggest {
  display: flex;
  width: 250px;
  border: 1px solid var(--dark-white);
}

.suggest > div {
  width: 100%;
}

.suggest input {
  width: 100%;
  height: 30px;
  line-height: 30px;

  font-size: 14px;

  color: inherit;

  padding: 0 35px 0 10px;
  border: 0;
  box-shadow: none;
}

.suggest input + div {
  left: -1px;
}

.suggest input + div > ul {
  border: 1px solid var(--dark-white);
}

.suggest input + div > ul > li {
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
}

.employee {
  display: flex;
  align-items: center;

  min-width: 240px;
}

.employee-item {
  display: flex;
  align-items: baseline;
}

.employee-wrap {
  display: flex;
  flex: 1;
}

.employee-item {
  flex: 1;
}

.employee-remove {
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  opacity: .5;
}

.employee-remove:hover {
  opacity: 1;
}

.employee-documents {
  margin-left: 5px;
  display: flex;
  align-items: center;
  flex: 0 0 60%;
}

.employee-documents span,
.employee-projects span {
  margin-right: 5px;
  color: var(--gray-text);
}

.employee-company {
  width: 250px;
}

.employee-company-select {
  color: var(--brand);
  cursor: pointer;
}

.employee-company-name {
  padding: 0 10px;
  color: var(--gray-text);
}

.employee-company > div > div > div > i {
  opacity: .5;
}

.employee-document {
  margin: 0 10px;
  flex: 0 0 40%;
}

.add-employee-button {
  padding-right: 5px;
  color: var(--dark-white);
  font-weight: 400;
}

.employee-projects {
  display: flex;
  align-items: center;

  margin: 0 10px;
}

.employee-multi {
  display: flex;
  flex-direction: column;
}

.employee-multi .employee-projects {
  margin: 15px 10px 0 -10px;
}

.employee-not-document {
  display: flex;
  align-items: center;
}

.select {
  width: 300px;
}
