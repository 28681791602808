@import '../../../app/styles/variables.css';

.form_wrap {
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: space-between;
  & .input_wrap {
    width: 70%;
    & .autocomplete {
      display: flex;
      position: absolute;
      background-color: var(--white);
      z-index: 6;
      width: 500px;
      top: 100%;
      left: 1.5rem;
      box-sizing: border-box;
      & .items_wrap {
        width: 100%;
        & .autocomplete_item {
          display: flex;
          justify-content: space-between;
          padding: .3rem 1rem;
          width: 100%;
          align-items: center;
          line-height: var(--font-size-md);
          min-height: var(--aside-nav-height);
          & .autocomplete_item_text {
            width: 66%;
          }
          & .autocomplete_item_label {
            font-size: 12px;
            color: var(--gray-text);;
          }
          &:hover {
            opacity: 1;
            background-color: var(--min-black);
            cursor: pointer;
          }
        }
      }
      & .label {
        padding: .5rem 1rem;
        color: var(--dark-gray-text);
      }
    }
  }
  & .button_wrap {
    font-size: 11px;
  }
}

.autocomplete-item {
  display: flex;
  padding: .3rem 1rem;
  align-items: center;
  line-height: var(--font-size-md);
  min-height: var(--aside-nav-height);

  &:hover {
    opacity: 1;
    background-color: var(--min-black);
    cursor: pointer;
  }
}

