@import '../../../../app/styles/variables.css';
@import '../../../../app/styles/mixin.css';

.warning {
  color: var(--danger);
}

.action {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action_tooltip {
  display: flex;
  justify-content: space-between;
}
