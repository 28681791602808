.search {
  display: flex;
  align-items: center;

  padding: 0 10px 0 10px;
}

.actions {
  display: flex;
  align-items:center;
}

.actions button {
  min-width: 150px;
  margin-right: 10px;
}

.tooltip {
  width: 310px;
  padding: 10px 15px;
}

.select_lable {
  color: #828893;
  font-size: 12px;
  max-height: 17px;
  opacity: 1;
  position: absolute;
  top: 3px;
  right: 210px;
}

  