@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.wrapper {
  color: var(--full-white);
  width: 303px;
  padding: 10px;
  background-color: var(--blue);
  border-radius: 8px;
  text-align: center;

  &.red {
    background-color: var(--brand);
  }

  &.pointer {
    cursor: pointer;
  }
}