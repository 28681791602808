@import '../../../app/styles/variables.css';

.contaiener {
  width: 100%;
}

.wrap {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.wrap_lable_top {
 position: relative; 
}

.label {
  position: absolute;
  top: 0;
  left: 0;
}

.textarea {
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  border-color: var(--border-color-sw-ui);
  &.with_label_top {
   margin-top: 25px !important;
  }
  &:focus {
    border-color: var(--primary);
    border-width: 2px;
    opacity: 1;
    & + .label {
      color: var(--primary);
      opacity: 1;
    }
  }
}

.invalid_area {
  border: 1px solid var(--danger);
}

.error {
  display: block;
  margin-top: 10px;
}