.profile-card a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0;

  display: flex;
}

.avatar {
  position: relative;

  width: 40px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
}

.avatar img {
  border-radius: 500px;

  width: 40px;
  height: 40px;

  line-height: 40px;
  text-align: center;

  vertical-align: middle;
}

.card {
  padding-left: 1rem;

  display: flex;
  flex-direction: column;

  justify-content: center;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}

.position {
  color: inherit;
  opacity: .6;

  text-transform: capitalize;
}
