.wrapper {
  height: 200px;
}

.search_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .total {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.header .total .label {
  font-size: 14px;
  color: #757575;
  margin: 0;
}

.header .total .amount {
  margin-top: 4px;
  font-size: 22px;
  color: #151515;
  font-weight: bold;
}

.search_wrapper .item {
  margin-left: 5px;
}

.table_wrapper {
  margin-top: 15px;
}
