@import '../../../../../app/styles/variables.css';

.employee-documents,
.employee-document {
  display: flex;
  align-items: center;
}

.employee-document {
  font-weight: 300;
  font-size: 13px;

  color: var(--dark-black);
}

.employee-documents span {
  margin-right: 5px;
}

.select {
  width: 400px;
}

.employee-document span {
  margin-left: 5px;
}

.item-select {
  font-size: 8px;
  font-style: italic;
  color: #828893;
}
