@import '../../app/styles/variables.css';

.main {
  padding: 1.5rem;
}

.row {
  display: flex;
  justify-content: left;
  padding-left: 10px;
  margin-bottom: 10px;
}

.row-title {
  margin-bottom: 20px;
  color: var(--dark-gray-text);
}

.col {
  margin: 5px 5px;
  text-align: left;
}
/* Lost generated */
.col-1-7 {
  width: calc(99.9% * 1/7 - (30px - 30px * 1/7));
}

.col-1-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-1-7:last-child {
  margin-right: 0;
}

.col-1-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-1-7:nth-child(7n + 1) {
  clear: both;
}

.col-3-7 {
  width: calc(99.9% * 3/7 - (30px - 30px * 3/7));
}

.col-3-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-3-7:last-child {
  margin-right: 0;
}

.col-3-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-3-7:nth-child(7n + 1) {
  clear: both;
}
/* Lost generated end */

.wrap {
  padding: 20px;
  background-color: var(--full-white);
}

.main-title {
  font-size: var(--font-size-lg);
  margin: 30px 0 20px 0;
  padding-left: 20px;
}

.render-panel {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;
  min-height: 40px;
  background-color: var(--white);
}

.top-control-panel {
  display: flex;
  margin-bottom: 40px;
}

.bottom-control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-button {
  width: 160px;
}

.button-to-top {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  left: 0;
}

.button-to-top:hover {
  background-color: var(--min-black);
  opacity: 1;
}

.button-to-top span {
  padding-left: 5px;
}

.button-wrap {
  margin-left: 50px;
}

.company-name {
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.company-item:hover {
  background-color: var(--main-background-color);
  transition: .3s cubic-bezier(0, 0, 0.2, 1);
}

.select-company {
  width: auto;
  align-self: end;
  margin: 3px 15px;
}

.title:hover {
  color: var(--brand);
}

.select-title {
  color: var(--brand);
}

.file-type-box {
  display: none;
  align-items: center;
  margin-left: auto;
}

.visible {
  display: flex;
}

.dialog {
  padding: 20px;
}

.dialog-text {
  margin-bottom: 40px;
}

.dialog-button {
  display: flex;
  justify-content: flex-end;
}

.send {
  margin-right: 20px;
}

.filter {
  margin-bottom: 20px;
  width: 30%;
}

.count {
  margin-top: 10px;
  color: var(--brand);
}
