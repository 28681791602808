@import '../../../../app/styles/variables.css';
@import '../../../../app/styles/mixin.css';

.format_form {
  margin: 16px;

  & .radio {
    & > div {
      padding: 10px 0;
    }
  }

  & .buttons {
    display: flex;
    margin-top: 10px;
  }

  & .error {
    color: var(--danger);
  }
}

