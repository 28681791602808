.upload_form {
  padding: 7px 0;
  width: calc(100%/4);
  height: auto;
  min-width: 250px;
  border-radius: 0;
}

.additional_info {
  display: flex;
  gap: 10px;
}

.textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  height: 100%;
  min-height: 130px;
  resize: none;
}