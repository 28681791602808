@import '../../../../../app/styles/variables.css';

.loading {
  display: flex;
  height: 336px;
}

.top,
.bottom {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
}

.col {
  display: flex;
  flex-direction: column;
}

.edit {
  width: 200px;
  margin-left: 10px;
}

.row {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  &.byContent {
    display: inline-block;
  }
}

.row button {
  width: auto;
}

.row :global(.sw-input__wrap) {
  width: 300px;
}

.bottom .row {
  padding-bottom: 0;
}

.bottom {
  flex-direction: column;
}

.bottom__action {
  display: flex;
}

.bottom__debt {
  margin-top: 20px;

  color: var(--danger);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.left {
  flex-grow: 1;
  max-width: 85%;

  & .smart_agent {
    font-size: var(--font-size-md);
    color: var(--danger);
    margin: 0 var(--main-margin);
  }

  & .smartway_kz {
    font-size: var(--font-size-md);
    color: var(--kz);
    margin: 0 var(--main-margin);
  }
}

.actions {
  display: flex;
}

.action {
  margin-left: 6px;
}

.name {
  padding: 8px;
}

.plan_fee:hover {
  opacity: 1;
  background-color: var(--min-black);
  cursor: pointer;
}

.account_settings:hover {
  opacity: 1;
  background-color: var(--min-black);
  cursor: pointer;
}

.value {
  font-weight: 500;
  margin-right: 12px;
}

.right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  width: 200px;

  & .aggregation {
    display: flex;
    align-items: center;

    margin-top: 20px;
  }
}

.companies-group-icons {
  margin-left: 2px;
  margin-right: 5px;
  font-size: 1rem;
}

.companyName {
  display: flex;
  align-items: center;
  width: 300px;

  & div span i {
    font-size: 1rem !important;
  }
}


.add,
.test {
  display: flex;
  align-items: center;
}

.test span {
  margin-left: 6px;
}

.select {
  margin: 0 12px;
  min-width: 400px;
}

.editable-comment {
  align-items: flex-end;
}

.textarea {
  padding: 6px;
  width: 100%;
  height: 100px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  border-color: var(--border-color-sw-ui);
}

.edit-button {
  flex: 0 0 auto;
}

.comment-text {
  margin-bottom: 3px;
  margin-top: 3px;
}

.account {
  font-size: 20px;
}

.dialog_plan_fee {
  margin: 20px;

  & .plan_fee_header {
    text-align: center;
  }
}

.plan_fee_wrap {
  display: flex;

  padding: var(--main-padding);
}

.plan_fee_col {
  display: flex;
  flex-direction: column;
  margin: var(--main-border-radius);

  & .plan_fee_name {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    color: var(--dark-gray-text);
  }

  & .no-padding {
    padding: 0;
  }
}

.dialog_account_settings {
  margin: 20px;

  & .account_settings_header {
    text-align: center;
  }
}

.account_settings_subtitle {
  font-size: 1rem;
  width: fit-content;
  padding: 0.5rem;
}

.account_settings_container {
  margin-left: 15px;
}

.account_settings_wrap {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.5rem;
}

.account_settings_name {
  margin-left: 10px;
}

.established_limits {
  padding: 15px
}

.established_limits_header {
  text-align: center;
}

.established_limits_row {
  padding: 6px 0;
}

.additional_limits {
  color: red;
}

/* Lost generated */
.col-1-2 {
  width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
}
.col-1-2:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.col-1-2:last-child {
  margin-right: 0;
}
.col-1-2:nth-child(2n) {
  margin-right: 0;
  float: right;
}
.col-1-2:nth-child(2n + 1) {
  clear: both;
}
/* Lost generated end */

.plan_fee_select {
  width: 200px;

  & .start-date {
    padding: 0 !important;
  }
}

.fee_select {
  width: 200px;
}

.button_plan_fee {
  width: 130px;
  color: var(--dark-gray-text) !important;
}

.button_del_plan_fee {
  margin-top: 30px;
}

.button_add_plan_fee {
  margin-left: 20px;
}

.left .amplitude {
  padding: 8px 0 20px;
}

.row .amo_btn {
  margin-left: 10px;
  padding: 8px 18px;
}

.extended_info:hover {
  cursor: pointer;
}

.accountDisableButton {
  margin-top: 10px;
}

.disable_account_wrapper {
  padding: 20px 20px 10px;
}

.disable_account_buttons {
  margin-top: 10px;
}

.select_row {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .settings_select {
    width: 140px;

    & div {
      color: #7A91B2;
    }
  }

  & .select {
    min-width: auto !important;
    border: none !important;
    margin: 0px !important;
  }
}

.settings_company_prohibition {
  flex-direction: row;
  padding: 0 !important;
}

.prohibition_row div {
  color: #7A91B2;
  opacity: 1;
  font-size: 14px !important;
}

.prohibition_row label {
  opacity: 1;
}

.settings_name {
  margin-left: 38px;
}

.tariff_text {
  margin: 10px 100px 0px 2px;
}

.calculation {
  margin-top: 10px;
}

.lead_type {
  margin-top: 30px;
  width: 160px;
}
