.analytic {
  width: 300px;
}

.cart_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  margin-bottom: 30px;
}
