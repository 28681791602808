@import '../../../app/styles/variables.css';

.wrap {
  background-color: var(--white);
  margin: var(--main-padding);
  padding: var(--main-padding);

  & .fields {
    column-count: 3;
  }

  & .buttons {
    display: flex;
    gap: 14px;

    & .button {
      width: 200px;
    }
  }

  & .error {
    color: var(--danger);
  }

  & .dialog_warning {
    padding: 20px;
  }
}