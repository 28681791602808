@import '../../app/styles/variables.css';
@import '../../app/styles/mixin.css';

.wrapper {
  align-self: center;
  width: 40px;

  & .button {
    background: none;
    border: none;;
    color: var(--success);

    &.button_close {
      color: var(--danger);
      width: 10px;
    }

    &:disabled {
      color: var(--gray-text);
    }
  }
}

