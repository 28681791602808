@import '../../../../../app/styles/variables.css';

.wrap_fields,
.date {
  display: flex;
}

.wrap_fields {
  border-top: 1px solid var(--border-color);

  margin: 20px 0 0 55px;
  padding-top: 20px;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-right: 40px;
}

.col:last-child {
  margin-right: 0;
}

.label {
  font-size: 13px;
  margin-bottom: 10px;
}

.date_picker {
  flex: 0 0 70%;
}

.time_input {
  border-left: none;
}
