@import '../../../../../app/styles/variables.css';

.empty {
  padding: 64px 0;
}

.table-header,
.table-body,
.table-row {
  display: flex;
}

.table-header {
  padding: 6px 0 6px 0;
  color: var(--light-black);
  border-top: 1px solid var(--border-color-sw-ui);
  border-bottom: 1px solid var(--border-color-sw-ui);
}

.table-row {
  padding: 6px 0;
  border-bottom: 1px solid var(--border-color-sw-ui);
}

.table-row:hover {
  background-color: var(--light);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-dialog {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}

.row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
}
.row:last-child {
  margin-bottom: 0;
}

.action {
  justify-content: flex-end;
}

.table-body {
  flex-direction: column;
}

.col-1-8 {
  width: calc(100% / 8);
}

.col-5-8 {
  width: calc(100% / 1.6);
}

.col-4-8 {
  width: calc(100% / 2);
}

.editable {
  display: flex;
  padding-right: 20px;
}

.edit {
  display: none;
  cursor: pointer;
}

.table-row:hover .edit {
  display: block;
}

.editable i.edit {
  height: 16px;
  font-size: 18px;
  padding-left: 12px;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.to-top {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  cursor: pointer;
}

.to-top-text {
  visibility: hidden;
}

.to-top:hover .to-top-text {
  visibility: visible;
}

.amt-opts {
  display: flex;
  align-items: center;

  margin: 8px 18px;
}

.amt-opt {
  padding: 8px;
  margin-left: 18px;

  border-radius: 50%;
  cursor: pointer;
}

.amt-opt.active {
  background: var(--bg-blue-sw-ui);
  color: var(--full-white);
}

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  min-height: 120px;
}

.dialog .header {
  font-size: 16px;
}

.right {
  margin-top: 24px;
  margin-left: auto;
  justify-content: flex-end;
}

.employee {
  margin-bottom: 0.2rem;
  border-bottom: 1px dashed;
}

.employee:hover {
  color: red;
}
