@import '../../app/styles/variables.css';
@import '../../app/styles/mixin.css';

.wrap {
  min-width: 1160px;
  padding: var(--main-padding);
}

.wrap.wrap-empty {
  height: 100%;
}

.wrap .panel {
  display: flex;
  flex-direction: column;

  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);

  padding: 1rem 0 1rem 0;
  position: relative;

  margin-right: 1rem;
}

.wrap .row,
.panel .row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
}
.wrap .row:last-child,
.panel .row:last-child {
  margin-bottom: 0;
}

.wrap .panel.list {
  padding: 0 0 1rem 0;
}

.form .row {
  margin: 0;
  padding: 0 1rem;

  min-height: var(--aside-nav-height);
}

/* Lost generated */
.panel .col-1-16 {
  width: calc(99.9% * 1/16 - (30px - 30px * 1/16));
}

.panel .col-1-16:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.panel .col-1-16:last-child {
  margin-right: 0;
}

.panel .col-1-16:nth-child(16n) {
  margin-right: 0;
  float: right;
}

.panel .col-1-16:nth-child(16n + 1) {
  clear: both;
}

.panel .col-3-16 {
  width: calc(99.9% * 3/16 - (30px - 30px * 3/16));
}

.panel .col-3-16:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.panel .col-3-16:last-child {
  margin-right: 0;
}

.panel .col-3-16:nth-child(16n) {
  margin-right: 0;
  float: right;
}

.panel .col-3-16:nth-child(16n + 1) {
  clear: both;
}

.panel .col-3-4 {
  width: calc(99.9% * 3/4 - (30px - 30px * 3/4));
}

.panel .col-3-4:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.panel .col-3-4:last-child {
  margin-right: 0;
}

.panel .col-3-4:nth-child(4n) {
  margin-right: 0;
  float: right;
}

.panel .col-3-4:nth-child(4n + 1) {
  clear: both;
}
/* Lost generated end */

.row.click {
  cursor: pointer;
  padding: 10px 16px;
}

.row.click:hover {
  background-color: var(--min-black);
}

.form {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.form label {
  font-size: var(--font-size-sm);
  opacity: .6;
}

.empty-list {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  text-transform: uppercase;
  opacity: .4;
  font-size: var(--font-size-md);
}

.link {
  opacity: .5;
  text-decoration: underline;
}

.link:hover {
  opacity: 1;
  text-decoration: none;
}

.cancel_btn {
  width: 400px;
}

.action_buttons {
  display: flex;
  gap: 12px;

  margin: 12px 24px;
}
