@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.row {
  margin-right: -15px;
  margin-left: -15px;

  height: 100%;
}

.row h2 {
  padding: 30px 0;
}

.carts {
  height: 100%;
}

.loading {
  display: flex;
  height: 100%
}

.wrap-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 45px;
}

.wrap-list {
  display: flex;
  flex-direction: column;

  padding: 10px 0;
}

@media all and (-ms-high-contrast:none) {
  .wrap-list {
    display: block;
  } /* IE11 */
}

.item_panel {
  margin-bottom: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 20px;
}

.actions-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--border-color);
  margin: 15px 0 0 55px;
  padding-top: 10px;
}

.employees {
  display: flex;
  align-items: flex-end;
  min-height: 35px;

  flex: 1;
}

.employees ul {
  list-style: none;
  padding-left: 0;
}

.actions {
  display: flex;
  align-items: center;
}

.actions-price {
  font-size: 24px;
  margin-right: 20px;
}

.wrap-actions .actions-button > button {
  padding: 10px 15px;
}

.actions-button .tooltip-content {
  padding: 10px;
  font-weight: bold;
}

.reserved-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 45px;

  width: 22px;
  height: 40px;

  background-color: var(--primary);
  color: #fff;
  font-size: 24px;
}

.item_wrap-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  padding: 0 20px;
}

.item_wrap-actions .action {
  margin-left: 20px;
  font-size: 13px;

  opacity: .7;
}

.item_wrap-actions .action:hover {
  opacity: 1;
}

.item_wrap-actions .action:first-child {
  margin-left: 0;
}

.timer {
  margin-top: 20px;
}

.approve-dialog-content {
  padding: 20px;
  background-color: var(--full-white);
}

.approve-comment {
  margin: 10px 0;
  resize: none;
}

.approve-person {
  font-weight: bold;
}

.approvers-list {
  margin-left: 20px;
  display: flex;
  flex: 0;
  justify-content: flex-start;
  flex-direction: column;
}

.approvers-list-item-checkbox {
  margin-bottom: 10px;
}

.approvers-list-item-radio {
  margin-bottom: 10px;
  height: 20px;
}

.approvers-list-item-checkbox .checkbox-display-fix,
.approve-dialog-content .checkbox-display-fix {
  display: flex;
}

.approve-types {
  margin-left: 20px;
}

.approve-types .approve-types-item {
  margin-bottom: 10px;
}

.item-wrapper {
  display: flex;
  flex: 1;
}

.delete-dialog-content {
  padding: 20px;
}

.delete-dialog-content h2 {
  padding: 0 0 30px 0;
}

.delete-link {
  margin-left: 15px;
}

.buttons-offline {
  display: flex;
}

.offline-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 55px;
}

.button-offline {
  border: 2px solid var(--filter-boder);

  margin-left: 10px;
}

.tp-text {
  cursor: pointer;
  text-decoration: underline dotted var(--danger);
  color: var(--danger);
}
