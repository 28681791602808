.dialog {
  padding: 20px;
}

.refund_text {
  margin-bottom: 50px;
}

.buttons_row {
  display: flex;
  column-gap: 30px;
}

.input {
  margin-bottom: 30px;
}

.dialog_loader {
  left: 0;
  top: 0%;
}