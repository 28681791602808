@import '../../app/styles/variables.css';

.button {
  background-color: transparent;
  border: none;
  height: 24px;
}

.icon:hover {
  color: var(--brand);
}
