.item {
  display: flex;
  flex-direction: column;

  flex: 1;

  & .name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    margin-bottom: 12px;
  }
}