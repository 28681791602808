.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  padding: 25px;
  height: 150px;
  justify-content: space-between;

  & .header {
    font-size: 1.5em;
  }
  & .form {
    display: flex;
    align-items: center;
  }

  & .alignCenter {
    display: flex;
    align-items: center;
  }
  & .paddingLeft {
    padding-left: 15px;
  }
  & .paddingAround {
    padding: 0 10px;
  }
  & .emptyPadding {
    padding: 0;
  }
}
