@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.auto-input {
  position: relative;
}

.auto-input > div {
  width: 100%;
}

.auto-input div input {
  position: relative;
  background: transparent;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid var(--border-color-sw-ui);
  width: 100%;
  min-height: 34px;
  padding: 2px;
  color: inherit;
  box-sizing: border-box;

  font: inherit;
  &:disabled {
    color: var(--gray-text);
  }
}

.auto-input div input:focus {
  border-color: var(--primary);
  border-bottom-width: 2px;
  padding-bottom: 1px;
}

.auto-input.no-valid div input {
  border-bottom-color: var(--danger);
}

.error-msg {
  line-height: 2;
  color: var(--danger);
}

.autocomplete-item {
  display: flex;
  padding: .3rem 1rem;
  align-items: center;
  line-height: var(--font-size-md);
  min-height: var(--aside-nav-height);
}

.autocomplete-item:hover {
  opacity: 1;
  background-color: var(--min-black);
  cursor: pointer;
}

.auto {
  display: flex;
  align-items: center;
  width: 100%;

  position: relative;
  height: 37px;
}

.auto > div {
  width: 100%;
}

.button {
  background: none;
  border: none;;
  margin-left: var(--main-margin);
  cursor: pointer;

  position: absolute;
  right: 0;
  z-index: 5;
  &:hover {
    color: var(--full-black);
  }
  &:disabled {
    color: var(--gray-text);
  }
}

.light{
  color: var(--dark-gray-text);
  display: block;
  margin-top: 10px;
  font-size: var(--font-size-sm);
}
