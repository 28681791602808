@import '../../../../../../app/styles/variables.css';

.requestsWrap,
.requestsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header {
  font-weight: 500;
}

.rows_wrap {
  display: flex;
  gap: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 95%;
}

.customer {
  text-align: left;
}

.noRequests {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 100%;

  text-transform: uppercase;
  opacity: .4;
  font-size: var(--font-size-md);
}

.list_item,
.header_label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100%/7);
  min-height: 2.25rem;
  text-align: left;
  gap: 3px;
}

.header_label {
  color: var(--gray-text);
}

.loading {
  display: flex;
  height: 200px;
}

.status_incomplete {
  color: var(--danger)
}

.status_pending {
  color: var(--warn)
}

.status_confirmed {
  color: var(--success)
}

.status_tripAdded {
  color: var(--accent)
}

.intercomLink {
  width: min-content;
  text-decoration: underline;
}

.button_wrap {
  font-size: 16px;
  background: none;
  outline: none;
  color: var(--black);

  &:hover {
    background: none;
  }
}

.item_highlight {
  background: none;

  &:hover {
    background: rgba(0, 0, 0, .15)
  }
}
