@import '../../../app/styles/variables.css';

.row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
}
.row:last-child {
  margin-bottom: 0;
}

.tickets {
  display: flex;
}

.row_ticket {
  width: 180px;
}

.row_error {
  width: 400px;
}

.form {
  flex-grow: 1;
}

.dialog {
  min-width: 400px;
}

.dialog .title,
.penalty_wrap .title {
  font-weight: 500;
  font-size: var(--font-size-md);
}

.dialog .action,
.penalty_action {
  justify-content: flex-end;
}

.row.action button,
.penalty_action button {
  width: auto;
}

.rate-alert {
  color: var(--danger);
}

.penalty_wrap {
  padding: 1.5rem;
}

.penalty__cancel_title {
  margin-top: 25px;
  font-size: var(--font-size-md);
}

.penalty__cancel_checkbox {
  margin-bottom: 5px;
}

.penalty__cancel_segments {
  margin: 15px 0;
  font-size: var(--font-size-md);
}

.penalty_radiobutton {
  margin: 10px 0;
}

.text_area {
  font-size: 14px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  height: 300px;
}

.error-text {
  color: var(--brand);
  font-size: 11px;
  margin-top: -15px;
  margin-bottom: 4px;
}

.info {
  font-size: 0.9rem;
  padding-top: 5px;
}

.notification {
  color: var(--danger);
}

.error-message {
  padding: 20px 0;
}

.input {
  padding-top: 30px;
}