@import '../../../app/styles/variables.css';

.wrap {
  display: flex;

  & button {
    width: auto;
  }

  & .dialog {
    flex-direction: column;

    padding: var(--main-padding);

    & .header {
     font-size: var(--font-size-md);
    }

    & .dates {
      margin: 20px 0;
    }

    & .format {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      & .select {
        margin-left: 10px;
        width: 200px;
      }
    }
  }
}
