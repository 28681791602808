@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.main {
  padding: var(--main-padding);

  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.upload {
  margin-bottom: 15px;
}

.text {
  font-size: var(--font-size-md);
  padding-bottom: 50px;
}

.button {
  display: flex;
}
