@import '../../../app/styles/variables.css';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabels {
  background-color: var(--primary-color);
  margin: 10px 30px;
}

.header {
  align-self: center;
}

.wrap_content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.item {
  display:flex;
  justify-content:center;
  text-align: center;
  gap:10px;

  width:100%;
  margin:5px 0px;
}

.row {
  display: grid;
  justify-items: stretch;
  align-items: center;
  grid-template-columns: 2.5fr 2fr 1.2fr 2fr 1.5fr;
  gap: 5px;

  width: 100%;
  padding: 10px;
}

.upload_wrap {
  padding: 20px;
}

.result_wrap {
  margin: 0 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
}

.result_header {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
}

.result_column {
  width: 25%;
  font-weight: 500;
  padding: 0 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  margin: 12px 32px 0px 0px;
}

.buttonUpdate {
  width: 170px;
  height: 35px;
  border-radius: 30px;
}


.button {
  width: 100px;
  height: 25px;
}
