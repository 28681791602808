@import '../../../app/styles/variables.css';

.wrapper {
  display: flex;
  flex-direction: column;

  padding: var(--main-margin);

  & .title {
    margin-bottom: var(--main-margin);

    font-weight: 500;
    font-size: var(--font-size-md);
  }

  & .list {
    display: flex;
    flex-direction: column;

    overflow: auto;
    max-height: 400px;

    & .item {
      margin-bottom: 10px;
      padding-bottom: 10px;

      border-bottom: 1px solid var(--border-color);

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;

        border-bottom: none;
      }

      & .name {
        margin-bottom: 5px;

        & span {
          font-weight: 500;
        }
      }
    }
  }
}
