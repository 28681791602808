@import '../../../app/styles/variables.css';

.wrapper {
  width: 116px;
}

.info {
  color: var(--danger);
  border-bottom: 1px dashed var(--danger);
  margin-bottom: 24px;
}

.info + div {
  z-index: 1030;
  width: 540px;
}

.name {
  font-weight: bold;
  padding-bottom: 4px;
}

.description {
  font-style: italic;
}

.messages {
  margin: 12px 0 0 0;
}

.messages-item {
  margin-bottom: 30px;
}
