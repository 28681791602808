@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.settings {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--full-white);
  box-shadow: 0 1px 3px var(--box-shadow-dark);
  gap: 24px;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  & .title {
    color: var(--full-black);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 105%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  & .title {
    color: var(--full-black);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  & .radio {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    & .radio_width {
      width: fit-content;
    }
  }
}