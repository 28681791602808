@import '../../../app/styles/variables.css';

.button {
    color: var(--full-white);
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    line-height: 36px;
    letter-spacing: 0.03em;

    transition: all 0.2s ease-in-out;
    display: inline-block;

    height: 36px;
    padding: 0 1rem;

    border: none;
    border-radius: 2px;
    cursor: pointer;
    touch-action: manipulation;

    background-image: none;
    vertical-align: middle;
    white-space: nowrap;
    user-select: none;
    position: relative;
    overflow: hidden;

    opacity: .8;

    background-color: var(--primary);
}

.button:hover {
    opacity: 1;
    background-color: var(--primary);
}

.button:disabled {
  background-color: var(--dark-gray-text);
  cursor: not-allowed;
}
/*.button:active {*/
/*}*/
