@import '../../../app/styles/variables.css';

.wrap {}

.dialog {
  padding: 20px;
}

.dialog h3 {
  margin-top: 0;
}

.row:not(:first-child) {
  margin-top: 10px;
}

.action {
  margin-top: 20px;

  display: flex;
  align-items: center;
}

.cancel {
  display: flex;
  align-items: center;

  margin-left: 20px;
}

.addCompanyButton {
  margin-top: 20px;
}

.add-company-actions {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addCompanyHeader {
  padding-bottom: 10px;
}

.wrapperRow {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.logo-label {
  padding-bottom: 10px;
}

.logo-voucher-label {
  padding: 15px 0;
}

.message {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;

  &.success {
    color: var(--primary);
  }

  &.error {
    color: var(--danger);
  }
}
