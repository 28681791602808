@import '../../../app/styles/variables.css';

.wrapper {
  padding: 20px;

  & .title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-md);

    margin-bottom: 20px;
  }

  & .guids {
    margin-bottom: 20px;

    & .text {
      margin-bottom: 10px;
      cursor: pointer;
      text-decoration: underline dashed;
      width: fit-content;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
      }

      & span {
        color: var(--danger);
      }

      &.download {
        text-decoration: none;
        cursor: not-allowed;
        opacity: .4;

        & span {
          color: var(--success);
        }
      }
    }
  }

  & .button {
    display: flex;
    justify-content: end;
  }
}
