@import '../../../../app/styles/variables.css';

.dialog {
  padding: 20px 40px;
}

.comment {
  margin: 10px 0;
}

.textarea {
  width: 100%;
  padding: 10px;
}

.checkbox {
  margin: 10px 0;
}

.error {
  color: var(--danger);
}