@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.panel {
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
}

.wrap {
  width:100%;
  display: flex;
  flex-direction: column;

  & .header {
    font-weight: var(--font-weight-medium);
  }

  & .item {
    display:flex;
    justify-content:space-between;
    width:100%;
    gap:10px;
    margin-top:5px;
    margin-bottom:5px;
    text-align:left;
    padding:2px;

    & .field_name {
      width:100%;
    }

    & .current_month {
      width:100%;
    }
    & .previous_month {
      width:100%;
    }
  }
}

.panel {
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
}

.loading {
  display: flex;
  height: 254px;
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.show_complaints {
  width: max-content;
  margin-top: 20px;
}


