@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.back_button {
  width: 100%;
  display: flex;
}

.add_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  gap: 4px;
  cursor: pointer;
  color: var(--blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background-color: transparent;
  border: none;
}

.add_button_disabled {
  cursor: not-allowed;
  color: var(--gray-text);
}

.edit_button {
  color: var(--blue);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background-color: transparent;
  border-bottom: 1px dashed var(--blue);
}

.delete_button {
  color: var(--brand);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background-color: transparent;
  border-bottom: 1px dashed var(--brand);
}

.save_button {
  padding: 8px 16px;
  background-color: var(--blue);
  border-radius: 2px;
}

.error {
  color: var(--brand);
  width: 102px;
  text-align: center;
}