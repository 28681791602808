@import '../../../../../app/styles/variables.css';

.wrap {
  position: relative;
  flex-direction: column;
  padding: 10px 10px 30px 10px;
  background-color: var(--full-white);
  display: flex;
}

.wrap_loader {
  padding: 10px 10px 30px 10px;
  background-color: var(--full-white);
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 30px 0 15px 4px;
}

.loading {
  display: flex;
  height: 200px;
}

.empty {
  padding: 64px 0;
}

.wrapper_employee {
  width: 165px;
}

.employee {
  margin-bottom: 0.2rem;
  border-bottom: 1px dashed;
  word-wrap: break-word;
}

.employee:hover {
  color: red;
}

.wrapper_block_employees {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 4px;
  width: 100%;
}

.modal_button {
  display: flex;
  position: absolute;
  right: 22px;
  top: 22px;
}
