@import '../../app/styles/variables.css';

.main {
  padding: 1.5rem;
  min-width: 950px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.row {
  display: flex;
  justify-content: left;
  padding-left: 10px;
}

.row-title {
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
}

.col {
  margin: 5px 5px;
  text-align: left;
}

/* Lost generated */
.col-1-7 {
  width: calc(99.9% * 1/7 - (30px - 30px * 1/7));
}

.col-1-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-1-7:last-child {
  margin-right: 0;
}

.col-1-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-1-7:nth-child(7n + 1) {
  clear: both;
}

.col-2-7 {
  width: calc(99.9% * 2/7 - (30px - 30px * 2/7));
}

.col-2-7:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.col-2-7:last-child {
  margin-right: 0;
}

.col-2-7:nth-child(7n) {
  margin-right: 0;
  float: right;
}

.col-2-7:nth-child(7n + 1) {
  clear: both;
}

/* Lost generated end */

.wrap {
  padding: 20px;
  background-color: var(--full-white);
}

.main-title {
  font-size: var(--font-size-lg);
  margin: 30px 0 20px 0;
  padding-left: 20px;
}

.render-panel {
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 40px;
  background-color: var(--white);
  gap: 10px;
}

.top-control-panel {
  display: flex;
  margin-bottom: 40px;
}

.bottom-control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-button {
  position: relative;
  width: 160px;
  margin: 0px 12px;
}

.tooltip {
  width: 310px;
  padding: 10px 15px;
  text-align: center;
}

.main_select {
  width: 179px;
}

.button-to-top {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  left: 0;
}

.button-to-top:hover {
  background-color: var(--min-black);
  opacity: 1;
}

.wrapper_btn_download {
  display: flex;
  align-items: baseline;
}

.wrapper_cerd_info {
  width: 30%;
  background-color: white;
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 20px;
}

.btn_download {
  margin-bottom: 0.2rem;
  border-bottom: 1px dashed;
  width: 55px;
  margin-left: 15px;
  font-white: 600;
}

.btn_download:hover {
  color: var(--danger);
  cursor: pointer;
}

.button-to-top span {
  padding-left: 5px;
}

.button-wrap {
  margin-left: 50px;
}

.company-name {
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.company-item:hover {
  background-color: var(--main-background-color);
  transition: .3s cubic-bezier(0, 0, 0.2, 1);
}

.change-company {
  width: auto;
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.title:hover {
  color: var(--brand);
}

.select-title {
  color: var(--brand);
}

.file-type-box {
  display: none;
  align-items: center;
  margin-left: auto;
}

.visible {
  display: flex;
}

.dialog {
  padding: 20px;
}

.dialog-text {
  margin-bottom: 40px;
}

.dialog-button {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 25%;
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.modal_body {
  font-size: 16px;
  display: flex;
  flex-direction: column;

  & .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & .title {
    color: var(--full-black);
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  & .radio {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
  }

  & .datepicker {
    display: flex;
    gap: 10px;
  }
}

.modal_buttons {
  display: flex;
  gap: 20px;
  margin-top: 4px;

  & .next {
    background-color: var(--primary);

    &:disabled {
      background-color: var(--dark-gray-text);
    }
  }
}

.last_step {
  flex-wrap: wrap;
}

.width_100 {
  width: 100%;
}

.autocomplete_input {
  position: relative;
  display: flex;
  flex-direction: column;
}

.autocomplete_input>div {
  width: 100%;
}

.autocomplete_input div input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid var(--border-color-sw-ui);
  width: 100%;
  min-height: 34px;
  padding: 2px;
  color: inherit;
  box-sizing: border-box;
  font: inherit;

  &:focus {
    border-color: var(--primary);
    border-bottom-width: 2px;
    padding-bottom: 1px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.autocomplete_item {
  display: flex;
  padding: .3rem 1rem;
  align-items: center;
  line-height: var(--font-size-md);

  &:hover {
    opacity: 1;
    background-color: var(--min-black);
    cursor: pointer;
  }
}

.success_message {
  overflow-wrap: anywhere;
}