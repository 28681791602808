@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.wrap {
  display: flex;
  align-items: flex-end;
}

.wrap.right {
}

.wrap.bottom {
  flex-direction: column;
}

.actions {
  display: flex;
  align-items: center;
}

.actions.right {
  margin-left: 6px;
}

.actions.bottom {
  margin-top: 10px;
}

.action {
  margin-left: 6px;
}

.textarea {
  font-size: 14px;
  padding: 6px;
  width: 100%;
}
