@import '../../../../../app/styles/variables.css';

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem;
  min-height: 120px;
}

.dialog .header {
  font-size: 16px;
}

.loading {
  display: flex;
  height: 254px;
}

.row {
  margin: 0;
  align-items: center;
  min-height: var(--aside-nav-height);
}

.right {
  margin-top: 24px;
  margin-left: auto;
  justify-content: flex-end;
}

.text {
  width: 100%;
}

.text_area {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  padding-top: 24px;
  margin-left: auto;
}

.action {
  margin-left: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}