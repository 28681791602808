@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.wrap {
    padding: var(--main-padding);

    min-height: 100%;
    display: flex;
    flex-direction: column;

    background: var(--white);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 4rem;
    margin-left: 1rem;
}

.row {
  width: 100%;
  height: calc(99.9% * 1 - (30px - 30px * 1));
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
}
.row:last-child {
  margin-bottom: 0;
}

.gap-16 {
  display: flex;
  gap: 4rem;
}

.form-cache {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    justify-content: space-between;

    padding: 1rem;
}

.form-provider {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  justify-content: space-between;
  align-self: center;

  padding: 1rem;
}

.row-cache {
    justify-content: flex-end;
}

.main {
    display: flex;
    flex: 1
}

.main button, .row.row-cache button {
    width: auto;
}

.compress {
    display: flex;
}

.button-close {
  float: right;
  border: 0;
  background: none;
}

.button-row {
  display: flex;
  margin: 10px;
}

.button-provider {
  margin-right: 10px;
}

.control {
  display: flex;
  align-items: center;
  place-content: space-between;
  width: 220px;
  margin-bottom: 10px;
}

.control-title {
  margin-right: 10px;
  width: 70px;
}

.control-checkbox {
  width: 10px;
}

.scheme-aggregation {
  width: 50%;
  margin-bottom: auto;
}

.setup-title {
  margin: 20px 0 10px 0;
  color: var(--dark-gray-text)
}

.setup-wrap {
  width: auto;
  margin-top: 10px;
}

.scheme-wrap {
  padding: 0 0 15px 15px;
}

.main-wrapper {
  display: flex;
  justify-content: space-around;
}
