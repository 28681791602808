.form_buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 15%;
}

.button_wrap {
  font-size: 11px;
  background: none;
  outline: none;
  color: var(--black);

  &:hover {
    background: none;
  }

  &:disabled {
    background-color: transparent;
  }
}

.new_trip_waiting,
.request_sending {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}