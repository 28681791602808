@import '../../../app/styles/variables.css';

.wrap {
  display: flex;
  align-items: center;

  & .date {
    width: 150px;
  }
}
.col {
  flex-direction: column;
}

.label_name {
  margin-right: 10px;
}
