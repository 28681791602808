.title {
  font-size: 24px;
  margin-bottom: 24px;
}

.content {
  margin: 18px 0;
}

.row {
  display: flex;
}

.col-1-3, .col-1-14, .col-2-14, .col-3-14, .col-4-14 {
  margin: 0 4px;
}

.col-1-2 {
  width: calc(100% / 2);
}

.col-1-3 {
  width: calc(100% / 3);
}

.col-1-14 {
  width: calc(100% / 14);
}

.col-2-14 {
  width: calc(100% / 7);
}

.col-3-14 {
  width: calc(100% / 4.66);
}

.col-4-14 {
  width: calc(100% / 3.5);
}

.label {
  padding: 2px;
}

.start-num {
  margin: 0;
  padding: 29px 2px 0 0;
}

.row.actions {
  justify-content: flex-end;
}

.big-loader {
  margin: 52px 0;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 250px;
}

.form-dialog {
  display: flex;
  justify-content: space-between;

  padding: 1.5rem;
  min-height: 120px;
}
