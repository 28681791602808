@import '../../../../../app/styles/variables.css';

.empty {
  padding: 64px 0;
}

.button-flag,
.button-correction {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray-sw-ui);

  & :hover {
    color: var(--full-black);
  }
}

.button-correction {
  padding: 0;
  margin-left: 8px;
}

.button-flag {
  padding: 3px 0;
}

.button-flag.active {
  color: var(--danger);
}

.table-info {
  padding: 12px 0;
  opacity: 0.8;
}

.table-header,
.table-body,
.table-row {
  display: flex;
}

.table-header {
  padding: 6px 0 6px 0;
  color: var(--light-black);
  border-top: 1px solid var(--border-color-sw-ui);
  border-bottom: 1px solid var(--border-color-sw-ui);
}

.table-row {
  padding: 6px 0;
  border-bottom: 1px solid var(--border-color-sw-ui);
  min-height: 100px;
}

.table-body {
  flex-direction: column;
}

.tooltip_flag {
  position: absolute;
  margin-top: 10px;
}

.email_tooltip_flag {
  position: absolute;
  margin-top: 47px;
}

.col-1-32 {
  width: calc(100% / 32);
}

.col-3-32 {
  width: calc(100% / 10.66);
}

.col-4-32 {
  width: calc(100% / 8);
}

.col-5-32 {
  width: calc(100% / 6.4);
}

.col-6-32 {
  width: calc(100% / 5.33);
}

.col-7-32 {
  width: calc(100% / 4.57);
}

.col-8-32 {
  width: calc(100% / 4);
}

.col-9-32 {
  width: calc(100% / 3.55);
}

.col-12-32 {
  width: calc(100% / 2.66);
}

.col-13-32 {
  width: calc(100% / 2.46);
}

.col-19-32 {
  width: calc(100% / 1.68);
}

.col-3-12 {
  width: calc(100% / 4);
}

.col-4-12 {
  width: calc(100% / 3);
}

.col-5-12 {
  width: calc(100% / 2.4);
}

.col-1-16 {
  width: calc(100% / 16);
}

.col-2-16 {
  width: calc(100% / 8);
}

.col-3-16 {
  width: calc(100% / 5.33);
}

.col-4-16 {
  width: calc(100% / 4);
}

.col-5-16 {
  width: calc(100% / 3.2);
}

.col-6-16 {
  width: calc(100% / 2.66);
}

.col-8-16 {
  width: calc(100% / 2);
}

.col-1-8 {
  width: calc(100% / 8);
}

.col-2-8 {
  width: calc(100% / 4);
}

.col-4-8 {
  width: calc(100% / 2);
}

.col-7-8 {
  width: calc(100% / 1.15);
  margin-left: 40px;
  padding-bottom: 20px;
}

.col-5-8 {
  width: calc(100% / 1.6);
  margin-left: 40px;
  padding-bottom: 20px;
}

.row-1-10 {
  width: calc(100% / 1);
  height: calc(100% / 10);
  display: flex;
}

.row-2-10 {
  width: calc(100% / 1);
  height: calc(100% / 5);
  display: flex;
}

.row-3-10 {
  width: calc(100% / 1);
  height: calc(100% / 3.33);
  display: flex;
}

.row-7-10 {
  width: calc(100% / 1);
  height: calc(100% / 1.42);
  padding-top: 12px;
  display: flex;
}

.row-9-10 {
  width: calc(100% / 1);
  height: calc(100% / 1.1);
  padding-top: 12px;
  display: flex;
}

.row-table {
  width: calc(100% / 1);
  height: calc(100% / 1);
  display: flex;
}

.info-changed {
  padding: 0 0 6px 0;
  max-width: 400px;
  margin: 9px 0 14px 0;
  color: var(--light-black);
  font-size: var(--font-size-sm);
}

.document {
  display: flex;
}

.icon {
  display: none;
  cursor: pointer;
}

.document i.icon {
  height: 16px;
  font-size: 18px;
  margin-left: 12px;
}

.document:hover .icon {
  display: block;
}

.icons-row {
  margin: 6px 6px 6px 0px;
}

.icons-row:first-child {
  margin-top: 0;
}

.icons-row:last-child {
  margin-bottom: 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.to-top {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  cursor: pointer;
}

.hedear_text {
  display: flex;
  font-size: var(--font-size);
}

.to-top-text {
  visibility: hidden;
}

.to-top:hover .to-top-text {
  visibility: visible;
}

.amt-opts {
  display: flex;
  align-items: center;
  margin: 8px 18px;
}

.table_flex {
  flex-direction: column;
}

.amt-opt {
  padding: 8px;
  margin-left: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.amt-opt.active {
  background: var(--bg-blue-sw-ui);
  color: var(--full-white);
}

.manual-changed {
  padding: 6px 0 6px 0;
  width: 420px;
  color: var(--light-black);
  font-size: var(--font-size-sm);
  white-space: normal;
}

.table_font_size {
  font-size: var(--font-size-sm);
}

.padding_doc {
  padding-right: 8px;
}

.padding_download {
  padding-left: 6px;
}

.comment {
  margin-top: 15px;
}

.email_flag {
  opacity: 0.3;
  cursor: pointer;
}

.email_flag:hover {
  opacity: 1;
}

.dialog_error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  min-height: 120px;
}

.right {
  margin-top: 24px;
  margin-left: auto;
  justify-content: flex-end;
}

.documents_wrap {
  display: flex;
  justify-content: space-between;

  & .button {
    display: flex;
    align-items: center;
  }
}

.documents,
.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}