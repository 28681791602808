.button_wrap {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.modal_wrapper {
    padding: 30px;
}

.text_wrapper {
    padding-bottom: 10px;
}

.button_modal_wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.button_modal {
    width: 90px;
    height: 30px;
}