@import '../../../app/styles/variables.css';

.item_wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  & .info {
    width: 100%;

    & .status {
      font-size: 16px;
      font-weight: 700;
      color: var(--brand);
    }

    & .content {
      padding: 20px;
      border: 1px solid var(--full-black);
      background-color: var(--full-white);
    }

    & .edit_template {
      background-color: var(--full-white);
      resize: none;
    }

  }

  & .content {
    width: 100%;
    min-height: 100%;
    padding: 20px;
    border: 1px solid var(--full-black);
    background-color: var(--full-white);
  }

  & .action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}