@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.col-1-3 {
  width: calc(100% / 3);
}

.header {
  font-size: 1rem;
  padding-bottom: 30px;
}

.red {
  color: #F44455;
}

.container {
  width: 1100px;
}

.validation {
  color: #F44455;
}

.validation_fee {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;

  & .provider {
    display: flex;
    flex-direction: column;

    & .select {
      margin-bottom: 20px;
    }
  }

  & .exchange_rates {
    display: flex;
    flex-direction: column;

    & .header {
      font-size: 1rem;
    }

    & .select {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  & .hidden_supplier {
    & .segment {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    & .interest_hidden_fee {
      margin-top: 30px;
    }
  }

  & .additional_fee {
    & .segment {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    & .interest_additional_fee {
      margin-top: 30px;
    }
  }
}

.buttons_wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 30px;

  & .button_clear {
    background-color: #eee;
    color: black;
  }
}
