.dialog {
  display: flex;
  flex-direction: column;
  padding: 20px;

  &_button_wrapper {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }

  &_button {
    width: 80px;
    height: 30px;
  }
}
