@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';


.wrapper_content {
  margin: 28px;

  & .error{
    color: var(--danger);
  }
}
