@import '../../app/styles/variables.css';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  padding: 25px;
  height: 150px;
  justify-content: space-between;

  & .dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1.5rem;
    min-height: 120px;
  }

  & .header {
    font-size: 1.5em;
  }
  & .form {
    display: flex;
    align-items: center;
    height: 50px;
  }

  & .alignCenter {
    display: flex;
    align-items: center;
  }
  & .paddingLeft {
    padding-left: 15px;
  }
  & .paddingAround {
    padding: 0 10px;
  }
  & .emptyPadding {
    padding: 0;
  }

  & .wrapper_block {
    position: relative;
    width: 160px;
    margin-right: 20px;
  }

  & .wrapper_input {
    width: 280px;
    margin-right: 20px;
  }

  & .tooltip {
    width: 310px;
    padding: 10px 15px;
  }

  & .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    color: var(--dark-gray-text);
    gap: 4px;
  }
}

.copy_button {
  border: none;
  background: none;
  outline: none;
  color: var(--dark-gray-text);
}
