.wrap {}

.dialog {
  padding: 20px;
}

.dialog h3 {
  margin-top: 0;
}

.row:not(:first-child) {
  margin-top: 20px;
}

.action {
  margin-top: 20px;

  display: flex;
  align-items: center;
}

.cancel {
  display: flex;
  align-items: center;

  margin-left: 20px;
}
