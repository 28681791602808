@import '../../../../../app/styles/variables.css';

.item {
  display: flex;
  flex-direction: column;

  flex: 1;
}

.name {
  display: flex;
  align-items: center;

  margin-bottom: 12px;
}

.name h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.name .stars {
  margin-left: 8px;
}

.address {
  display: flex;
  flex-direction: column;
}

.address .date_interval {
  margin-top: 4px;
  font-size: 13px;
  color: var(--dark);
}

.details {
  display: flex;
  margin-top: 20px;

  font-size: 13px;
  color: var(--dark-gray-text);
}

.block {
  padding-top: 4px;
  display: flex;

  font-size: 13px;
  color: var(--dark-gray-text);
}

.block b {
  font-weight: 500;
  color: var(--full-black);
}

.details b {
  font-weight: 500;
  color: var(--full-black);
}

.text {
  padding-top: 4px;
  color: var(--dark-gray-text);
  cursor: default;
  font-size: 13px;
}
