@import '../../../app/styles/variables.css';

.wrapped {
  padding: 24px;
  min-width: 400px;

  & .title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-md);

    margin-bottom: var(--main-padding);
  }

  & .actions {
    justify-content: flex-end;
    margin-top: 24px;
  }
}
