@import '../../app/styles/variables.css';

.autocomplete-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  & .suggest-input {
    & input {
      position: relative;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(160, 160, 160, 0.2);
      min-height: 34px;
      padding: 2px;
      color: inherit;
      box-sizing: border-box;

      border-top: none;
      z-index: 99;
      top: 100%;
      left: 0;
      right: 0;
    }

    & input:hover {
      border-color: var(--primary);
      border-bottom-width: 2px;
      padding-bottom: 1px;
    }

    & span {
      color: red;
      font-size: 12px;
    }
  }

  & .suggest-item {
    display: flex;
    padding: .3rem 1rem;
    align-items: center;
    line-height: var(--font-size-md);
    min-height: var(--aside-nav-height);
  }

  & .suggest-item:hover {
    opacity: 1;
    background-color: var(--min-black);
    cursor: pointer;
  }

  & .submit-button {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}

