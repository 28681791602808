.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.children-wrapper {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.wrapper {
  transition: 0.3s;
}

.arrow {
  transition: all 0.2s linear;

  &.down {
    transform: rotate(180deg);
  }

  &.up {
    transform: rotate(360deg);
  }
}