@import '../../../../../app/styles/variables.css';

.item {
  display: flex;
  flex-direction: column;
}

@media all and (-ms-high-contrast:none) {
  .item {
    display: block;
  } /* IE11 */
}

.item.not_count {
  margin-top: 30px;
}

.item.not_count:first-child {
  margin-top: 0;
}

.row {
  display: flex;
  flex: 1;
}

.row-item {
  margin-left: 5px;
}

.logo {
  width: 50px;
  text-align: center;
}

.logo .airline-logo {
  width: 30px;
  max-width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
}

.col_departure,
.col_arrival {
  flex: 1;
}

.col_airline {
  align-items: center;
}

.col_arrival {
  align-items: flex-end;
}

.date {
  font-weight: 500;
  line-height: 1.2;
}

.col h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 20px 0;
}

.capitalize {
  text-transform: capitalize;
}

.airport-departure,
.airport-arrival {
  line-height: 1;
}

.airport-arrival {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.airport-name {
  margin-top: 5px;

  font-size: 13px;
  line-height: 1;
  color: var(--dark-gray-text);
}

.airline {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 200px;
  margin-bottom: 20px;
}

.airline span {
  line-height: 1.2;
  color: var(--dark-gray-text);

  margin: 0 .5em;
}

.icon {
  font-size: 22px;
  line-height: 1;
}

.change {
  display: flex;

  padding: 20px 0;
  margin: 20px 0 20px 55px;

  border-bottom: 1px dotted var(--dark-gray-text);
  border-top: 1px dotted var(--dark-gray-text);

  line-height: 1;
  color: var(--dark-gray-text);
}

.change-title,
.change-duration {
  width: calc(100% / 3);
}

.change-duration {
  display: flex;
  justify-content: center;

  font-weight: 500;
}
