@import '../../../../../app/styles/variables.css';

.wrap {
  width: 100%;
}

.wrap input {
  width: 100%;
  padding: 9px;
  margin: 0;

  line-height: 20px;
  border: 1px solid var(--border-color-sw-ui);
}

.wrap.error > input {
  border: 1px solid var(--brand);
  background-color: var(--light-pink);
}

.wrap.error > span {
  margin: 4px 0 0;
  color: var(--brand);
  font-size: 11px;
}

.wrap > input[readonly] {
  opacity: .5;
}
