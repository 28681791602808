@import '../../../../../../../app/styles/variables.css';

.condition-text {
  padding-top: 4px;
  font-size: 13px;
}

.text {
  padding-top: 4px;
  color: var(--dark-gray-text);
  cursor: default;
  font-size: 13px;

  & .sold-out {
    text-decoration: line-through var(--brand);
  }
}
