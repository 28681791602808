@import '../../../../../app/styles/variables.css';

.wrap {
  padding: 24px;
}

.form {
  padding: 10px;
  letter-spacing: 0px;
}
.title {
  align-items: center;

  & h3 {
    margin: 5px 0 10px;
  }
}

.switch {
  display: flex;
  flex-direction: column;
}

.radios {
  align-items: flex-start;

  & .radio {
    margin-top: 10px;
  }
}

.error {
  margin-top: 15px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
}

.error-msg {
  line-height: 2;
  color: var(--danger);
  border-bottom-color: var(--danger);
}
.pattern_msg {
  width: min-content;
  white-space: nowrap;
  font-size: 11px;
  margin: 8px auto;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.pattern_button{
  width: 130px;
}

.empolyees_information {
  display: flex;
  flex-direction: column;
}

.employee_message {
  display: flex;
  flex-direction: column;

  & span {
    margin-top: 5px;
  }
}

.title {
  display: flex;
  justify-content: center;
}

.message {
  margin-top: 15px;
  font-size: 12px;
  opacity: 0.73;
}


.error_message {
  color: var(--danger);
  margin-top: 10px;
  font-size: smaller;
}

.main_row {
  margin-bottom: 15px;
  font-weight: 600;
}
