@import '../../../app/styles/variables.css';

.navigation {
  padding: 0;
  margin: 0;

  width: 100%;

  list-style: none;

  border: inherit;
}

.item {
  border: inherit;
  position: relative;
  width: var(--aside-width);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.item .item.disabled a:hover {
  background-color: transparent;
  cursor: not-allowed;
}

.item a {
  display: flex;
  align-items: center;
  padding: .3rem 1rem;
  line-height: 1.125rem;
  position: relative;

  color: inherit;
  opacity: .75;

  min-height: var(--aside-nav-height);
}

.icon {
  line-height: inherit;
  margin-right: 1rem;
  top: 0;
  min-width: 1.5rem;
  text-align: center;
}

.icon .icon-services {
  background-position: bottom 4px center !important;
}

.title {
  font-weight: 500;
}

.title > span {
  line-height: 1.125rem;
}

.arrow {
  font-family: 'FontAwesome', serif;

  position: absolute;
  right: 1rem;
}

.arrow::after {
  content: '\f0d7';
}

.sub {
  max-height: 0;

  padding: 0;
  margin: 0;

  width: 100%;

  list-style: none;

  overflow: hidden;

  border: inherit;

  transition: max-height 0.4s ease 0s;
}

.sub .item a {
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 3.5rem;
}
