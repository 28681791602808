@import '../../../app/styles/variables.css';

.wrap {
  display: flex;
  flex-direction: column;

  &.cart-view {
    & .item {
      display: list-item;
      font-size: 13px;
      color: var(--dark-gray-text);

      & b {
        font-weight: 500;
        color: var(--full-black);
      }
    }

    & .details {
      display: flex;
      flex-direction: row;
    }
  }
}

.wrap.fare-with-title {
  padding: 20px;
  margin-left: 50px;
}

.title {
  font-weight: 500;
  margin-bottom: 8px;
}

.custom_title {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
}

.fare {
  line-height: 1.5;
}

.fare_as_item {
  line-height: 1.2;
  font-size: 13px;
  color: var(--dark-gray-text);
  margin-right: 3px;
}

.fare-symbols:not(:nth-child(1)) {
  text-transform: lowercase;
}

