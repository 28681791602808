@import '../../../../app/styles/variables.css';
@import '../../../../app/styles/mixin.css';

.wrapper {
  margin-bottom: 20px;

  & .header {
    display: flex;
  }

  & .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    & .item {
      display: flex;
      flex-direction: column;

      & .row {
        display: flex;

        & .charge_value {
          display: flex;
          align-items: baseline;

          & .charge_value_fields {
            display: flex;
            margin-left: 10px;
          }

          & .charge_input {
            margin: 0 10px 0 10px;
            width: 200px;
          }

          & .charge_suggest_first {
            margin: 0 10px 0 10px;
            width: 170px;
          }

          & .charge_suggest_second {
            display: flex;
            align-items: baseline;
            width: 170px;
          }

          & .charge_time {
            display: flex;
            align-items: baseline;
            width: 360px;

            & .charge_time_label {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  & .delete_button {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
}

.error-msg {
  color: var(--danger);
  line-height: 2;
}

