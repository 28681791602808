@import '../../../app/styles/variables.css';
@import '../../../app/styles/mixin.css';

.row {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-top: 30px;
}

.row button {
  width: auto;
}

.name {
  padding: 2px;
  margin-right: 20px;
}

.value {
  font-weight: 500;
  margin-right: 12px;
}

.edit-button {
  margin-left: 20px;
  flex: 0 0 auto;
}

.comment-text {
  margin: 6px 0 0;
}

.comment-text:first-child {
  margin: 0;
}

