.dialog {
  padding: 24px;
}

.actions {
  display: flex;
  justify-content: flex-end;

  margin-top: 36px;
}
