body {
  /*font-family: Arial, sans-serif;*/
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  margin: 20px auto;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 30px;
}

.section h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
}

.section h1 {
  font-size: 26px;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
}

.info-row {
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info-row label {
  color: #666;
  font-weight: 500;
  margin-right: 20px;
  min-width: 120px;
  text-align: left;
}

.info-row span {
  color: #222;
  font-size: 14px;
}

.info-row .emoji {
  font-size: 20px;
}

.document {
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.document h3 {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333;
}

.bonus-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bonus-row span {
  flex: 1;
  font-size: 14px;
}

.bonus-row button {
  margin-left: 10px;
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.bonus-row button:hover {
  background-color: #0056b3;
}

.add-card {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 14px;
  color: #007bff;
  background: none;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.add-card:hover {
  background-color: #007bff;
  color: #fff;
}
