@import '../../../../../app/styles/variables.css';

.wrap {
  position: relative;
  padding: 16px;
  background-color: var(--full-white);
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 16px;
}

.loading {
  display: flex;
  height: 200px;
}

.empty {
  padding: 64px 0;
}

.table {
  padding: 6px;
}

.table-row {
  display: flex;

  padding: 6px 0;
}

.col-1-4 {
  width: calc(100% / 4);
}

.col-1-6 {
  width: calc(100% / 6);
}

.col-1-8 {
  width: calc(100% / 8);
}

.employee {
  margin-bottom: 0.2rem;
  border-bottom: 1px dashed;
  word-wrap: break-word;
}

.modal_button {
  display: flex;
  position: absolute;
  right: 22px;
  top: 22px;
}
