@import '../../../../../app/styles/variables.css';

.wrap {
  padding: 16px;
  background-color: var(--full-white);
}

.loading {
  display: flex;
  height: 200px;
}

.header {
  font-weight: bold;
  margin-bottom: 24px;
}

.date-range {
  display: flex;
  align-items: baseline;
}

.date-text {
  margin: 16px;
}

.date-text:first-child {
  margin-left: 0;
}

.position {
  display: flex;

  margin-left: 12px;
}

.link {
  cursor: pointer;
  border-bottom: 1px dashed var(--dark-black);
}

.link:hover {
  color: var(--brand);
  border-bottom: 1px dashed var(--brand);
}

.actions_wrapper {
  margin: 24px 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .checkbox_wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.actions_card {
  display: flex;
}

.action {
  margin: 6px 0;
}

.item_wrapper {
  width: 30px;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
}

.checkbox_text {
  width: 490px;
}

.action_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}