@import '../../app/styles/variables.css';

.wrapper {
  position: relative;

  padding: 20px;
  margin-top: 20px;


  & .button_close {
    position: absolute;
    right: 20px;
    padding: 8px 20px;
  }

  & .button {
    width: max-content;
  }

  & .wrapper_newTemplate {
    margin-top: 60px;

    & .action_newTemplate {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    & .textarea_newTemplate {
      background-color: var(--full-white);
      resize: none;
    }
  }

  & .no_templates {
    margin-top: 30px;

    font-weight: 700;
    text-align: center;
  }

  & .error {
    color: red;
    text-align: center;
    font-weight: 700;
  }
}
