@import '../../app/styles/variables.css';

.button {
  background-color: transparent;
  border: none;
}

.icon::before {
  display: block;
  content: attr(data-icon);
}
