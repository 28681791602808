@import '../../../../../../app/styles/variables.css';

.modal__wrapper {
  width: 800px;
  min-height: 300px;
}

.verssion__list{
  display: flex;
  align-items: center;
  gap: 30px;
}

.change__number__wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  min-height: 130px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  &.modal__input__wrapper {
    width: 70%;
  }
}

.modal__input {
  width: 70%;

  & input {
    text-align: center;
  }
}

.title__text {
  font-weight: var(--font-weight-bold);
}

.confirm__edit__text {
  padding: 0 50px;
  color: var(--brand);
}

.buttons__wrapper {
  gap: 200px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 20px 20px 0;
}