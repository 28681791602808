.container {
  padding: 24px;
}

.event_dates {
  display: flex;
  column-gap: 32px;
  margin-bottom: 32px;
  width: 100%;

  & > * {
    flex: 1;
  }
}

.event_date {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}

.comment_input {
  margin-bottom: 24px;
  min-width: 100%;
  min-height: 36px;
  max-height: 200px;
}

.creation_date_container {
  margin-top: 32px;
}

.select_login {
  border-bottom: 1px solid var(--filter-boder);

  & > div:first-child {
    padding: 0 0;
  }
}

.label,
.comment_input ~ label {
  font-size: var(--font-size-xs);
  opacity: .6;
}

.label:has(+ div input:focus) {
  color: var(--primary);
}

.date_picker {
  padding: 0 0;

  & input:focus ~ label {
    color: var(--primary);
  }
}

.action_buttons {
  display: flex;
}

.cancel_button {
  margin-left: 10px;
  background-color: transparent;
  color: var(--text-color);
  text-transform: none;

  &:hover {
    background-color: var(--gray-sw-ui);
  }
}

.error_border {
  padding-bottom: 4px;

  & input {
    border-bottom: 1px solid var(--danger);
  }
}
