@import '../../../../app/styles/variables.css';

.wrapper {
  padding: 22px;
  width: 480px;

  & .error_text {
    color: var(--danger);
  }

  & .rows {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 18px;

    & .field {
      width: 200px;

      & .datepicker {
        margin-bottom: 24px;
      }
    }
  }
}