.input {
  padding: 0;
  width: 250px;
  margin-right: 50px;
}

.chat_info_wrap {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.hotel_item {
  width: calc(100% / 5);
}

.user_selector {
  padding: 0;
  width: 250px;
}
