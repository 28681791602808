@import '../../../app/styles/variables.css';

.header {
  margin: 0;
  padding: 0 var(--main-padding);

  min-height: var(--menu-header-height);
  background-color: var(--white);
  display: flex;
}

.header .filter {
  align-self: center;
}

.header .search {
  display: flex;
  flex: .2 0;
}

.hotels_wrap {
  width: 70%;
  flex: 1 0 !important;
}
