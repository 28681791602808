.dialog {
  min-width: 360px;
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.warning {
   color: red;
}

.title {
  font-size: 18px;
}
.search {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.matchTrip {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 12px;
  background-color: #dedede;
}

.tripId {
  border-radius: 3px;
  padding: 4px;

  font-weight: bold;
  color: white;
  background-color: #57d9ad;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}
